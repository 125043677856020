import React, { useContext, useEffect } from "react";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../../context/auth-context.js";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer, IconButton, Hidden } from "@material-ui/core";

//icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// Components
import SearchBarDialog from "./SearchBarDialog";

import "../../styles.css";
import ListaMenu from "./ListaMenu";

import useStyles from "../../styles/App.style.js";

const useStyle = makeStyles((theme) => ({
  drawer: {
    background: "linear-gradient(180deg, #083876 0%, #02224B 100%)",
    height: "100%",
    zIndex: 3,
    width: "300px",
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
      "&::-webkit-scrollbar": {
        width: 5, // Ancho del scrollbar para navegadores basados en WebKit
      },
    },
    // transition:
    //   "margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

    //ESTILO SCROLL BAR
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5, // Ancho del scrollbar para navegadores basados en WebKit
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Color transparente al principio
      borderRadius: 3,
      height: 50,
    },
    "&:hover::-webkit-scrollbar-thumb": {
      backgroundColor: "#37ED9A", // color al pasar el mouse
    },
    // Estilos específicos de Firefox
    scrollbarWidth: "thin", // Para navegadores que admiten la propiedad scrollbar-width (como Firefox)
    scrollbarColor: "transparent transparent",
    "&:hover": {
      scrollbarColor: "#37ED9A transparent",
    },
  },
  drawerMin: {
    background: "linear-gradient(180deg, #083876 0%, #02224B 100%)",
    height: "100%",
    zIndex: 3,
    width: "120px !important",
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },
  drawerPaper: {
    background: "transparent",
    //width: 280,
    margin: "0px 10px",
    border: "none",
    display: "flex",
    height: "100%",
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },
  drawerPaperMin: {
    background: "transparent",
    width: 120,
    margin: "0px 10px",
    border: "none",
    display: "flex",
    height: "100%",
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },
  //contenedor header sidebar
  headerSidebar: {
    width: 280,
    //position: "fixed",
    zIndex: 3,
    paddingTop: 24,
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      //position: "sticky",
    },
  },
  headerSidebarMin: {
    width: 120,
    position: "fixed",
    zIndex: 3,
    paddingTop: 24,
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    "& img": {
      height: 32,
    },
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },
  toolbarMin: {
    display: "flex",
    justifyContent: "space-between",
    "& img": {
      height: 40,
      marginLeft: 20,
    },
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },

  toolbarImg: {
    marginBottom: 5,
  },
  //cerrar sidebar mobile
  drawerButton: {
    maxHeight: 20,
  },
  //barra de busqueda
  search: {
    display: "flex",
    width: "100%",
    backgroundColor: "#dddddd",
    borderRadius: 20,
    margin: "32px 0px",
    height: 40,
  },
  min: {
    width: 40,
    height: 40,
    marginLeft: 25,
    transition: "all .5s",
    "& >.MuiOutlinedInput-adornedStart": {
      paddingLeft: 10,
    },
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },
  //boton cerrar mobile
  closeIconButton: {
    margin: 0,
    padding: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const SideBar = (props) => {
  const theme = useTheme();
  const classes = useStyle();
  const classe = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const current = location.pathname;
  const isMobile = useMediaQuery("(max-width:600px)");
  const [oBusiness, setOBusiness] = React.useState(false);
  const [oProfile, setOProfile] = React.useState(false);
  const [oCollects, setOCollects] = React.useState(false);
  const [oMovements, setOMovements] = React.useState(false);
  const [minimal, setMinimal] = React.useState(false);

  useEffect(() => {
    let setMin = localStorage.getItem("minimal");
    setMinimal(JSON.parse(setMin));
    if (isMobile) {
      // console.log("es mobile cambiamos el minimal");
      // localStorage.setItem("minimal", false);
      setMinimal(false);
    }
  }, [localStorage, isMobile]);

  const handleMinimal = () => {
    props.setMinimal(!minimal);
    setMinimal(!minimal);
    localStorage.setItem("minimal", !minimal);
  };

  const handleClick = (opt) => {
    if (opt === "business") {
      setOBusiness(!oBusiness);
      setOProfile(false);
      setOCollects(false);
      setOMovements(false);
    } else if (opt === "profile") {
      setOProfile(!oProfile);
      setOCollects(false);
      setOBusiness(false);
      setOMovements(false);
    } else if (opt === "movements") {
      setOMovements(!oMovements);
      setOProfile(false);
      setOCollects(false);
      setOBusiness(false);
    } else {
      setOCollects(!oCollects);
      setOProfile(false);
      setOBusiness(false);
      setOMovements(false);
    }
  };

  // console.log("minimal2:", minimal);
  // console.log("minimal2L:", localStorage.getItem("minimal"));

  return (
    <Drawer
      transitionDuration={{
        enter: 200, // Tiempo de transición al expandir en milisegundos
        exit: 100, // Tiempo de transición al contraer en milisegundos
      }}
      variant={props.variant}
      classes={
        minimal
          ? {
              paper: classes.drawerPaperMin,
            }
          : { paper: classes.drawerPaper }
      }
      className={minimal ? classes.drawerMin : classes.drawer}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {/* ===== HEADER SIDEBAR ===== */}
      <div
        className={!minimal ? classes.headerSidebar : classes.headerSidebarMin}
      >
        <div className={!minimal ? classes.toolbar : classes.toolbarMin}>
          <img
            src={
              !minimal ? `/LogoTeoPagosWhite.svg` : `/icons/LogoIconOnly.svg`
            }
            className={classes.toolbarImg}
          />
          <Hidden smDown>
            <div onClick={handleMinimal}>
              <img
                src={!minimal ? `/icons/Menus.svg` : `/icons/PlusWhite.svg`}
                className={classe.linksOnly}
                style={{ marginRight: 30, width: 20 }}
              />
            </div>
          </Hidden>
          <Hidden smUp>
            <div className={classes.drawerButton}>
              <IconButton
                onClick={props.onClose ? props.onClose : null}
                className={classes.closeIconButton}
              >
                <ChevronLeftIcon style={{ color: "white", fontSize: 35 }} />
              </IconButton>
            </div>
          </Hidden>
          {/* <Avatar 
          title={`${auth.name} ${auth.lastname || ''}`}
          auth={auth}
          img={auth.photo ? auth.photo : "/avatars/avatar-1.jpg"}
          clase="primary"
          types="complex"
        /> */}
        </div>
        {/* Barra de busqueda */}
        <div className={[classes.search, minimal ? classes.min : ""].join(" ")}>
          <SearchBarDialog />
        </div>
      </div>
      {/* ===== FIN HEADER SIDEBAR ===== */}

      {/* ===== MENU SIDEBAR =====*/}
      <div>
        <ListaMenu
          minimal={minimal}
          numberNotifications={props.numberNotifications}
          closeDrawer={props.onClose}
        />
      </div>
      {/* ===== FIN MENU SIDEBAR =====*/}
    </Drawer>
  );
};

export default SideBar;
