import React, { useEffect, useState } from "react";

//Style
import { useMediaQuery } from "@material-ui/core/";
import useStyles from "../../styles/App.style.js";

//Componentes
import { Link } from "@material-ui/core";

export default function HeaderInvitado(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)"); // breakpoint para móviles aquí
  const [code, setCode] = useState("");
  const [urls, setUrls] = useState("");

  useEffect(() => {
    var curl = window.location.href;
    var segments = curl.split("/");
    segments.shift();
    var result = segments.slice(3).join("/");
    setCode(result);
  }, []);

  useEffect(() => {
    const url =
      process.env.REACT_APP_TEOID_URL +
      "/prelogging?app=teopagos&redirect=" +
      process.env.REACT_APP_TEOPAGOS_URL +
      "/signin&logo=" +
      process.env.REACT_APP_TEOPAGOS_URL +
      "/images/logo.png&backUrl=" +
      process.env.REACT_APP_TEOPAGOS_URL +
      "/pago/" +
      code +
      "&to=pago/" +
      code;
    setUrls(url);
  }, [code]);

  // console.log("url:", urls);

  return (
    <>
      <header className={classes.sectionHeader}>
        <div className={!props.logoOnly && classes.containerHeader}>
          {/*===== NOMBRE EMPRESA =====*/}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className={
                !props.logoOnly ? classes.tabsIcon : classes.tabsIconBig
              }
            >
              <img src="/avatars/avatar.png" alt="Teo Pagos" />
            </div>
            <div
              className={!props.logoOnly ? "txtFooter" : "txtFooterBig"}
              style={{ marginLeft: 5 }}
            >
              <p className={classes.txtHeaderNameCompany}>
                {props.companyName}
              </p>
            </div>
          </div>

          {/*===== LINK INGRESAR =====*/}
          {!props.logoOnly && (
            <div>
              <p
                className={classes.bodyXSR}
                style={{
                  color: "#8C8C8C",
                  margin: "5px 0",
                  textAlign: "right",
                }}
              >
                <Link
                  onClick={() => {
                    window.location.href = urls;
                  }}
                  color="inherit"
                >
                  Ingresar
                </Link>
              </p>
            </div>
          )}
        </div>
      </header>
    </>
  );
}
