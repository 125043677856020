import { useState, useCallback, useRef, useEffect } from "react";

// export const useHttpClient = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState();
//   const activeHttpRequests = useRef([]);

//   useEffect(() => {
//     let isMounted = true;
//     return () => {
//       isMounted = false;
//       activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
//     };
//   }, []);

//   const sendRequest = useCallback(
//     async (url, method = "GET", body = null, headers = {}) => {
//       setIsLoading(true);
//       const httpAbortCtrl = new AbortController();
//       activeHttpRequests.current.push(httpAbortCtrl);
//       try {
//         const response = await fetch(url, {
//           method,
//           body,
//           headers,
//           signal: httpAbortCtrl.signal,
//         });
//         const responseData = await response.json();
//         activeHttpRequests.current = activeHttpRequests.current.filter(
//           (reqCtrl) => reqCtrl !== httpAbortCtrl
//         );
//         if (!isMounted) {
//           return; // Component is unmounted, do not update state
//         }
//         /*
//             if (!response.ok || !responseData.success) {
//                 throw new Error(responseData.error || responseData.message || responseData.msg);
//             }
//             */
//         setIsLoading(false);
//         return responseData;
//       } catch (err) {
//         if (!isMounted) {
//           return; // Component is unmounted, do not update state
//         }
//         setError(err.message);
//         setIsLoading(false);
//         throw err;
//       }
//     },
//     []
//   );

//   const clearError = () => {
//     setError(null);
//   };

//   // useEffect(() => {
//   //     return () => {
//   //          // eslint-disable-next-line react-hooks/exhaustive-deps
//   //         activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
//   //     };
//   // }, []);
//   return { isLoading, error, sendRequest, clearError };
// };

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const activeHttpRequests = useRef([]);
  const cleanupFunction = useRef(false);

  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);
      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        });
        const responseData = await response.json();
        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );
        if (!cleanupFunction.current) {
          setIsLoading(false);
        }
        return responseData;
      } catch (err) {
        if (!cleanupFunction.current) {
          setError(err.message);
          setIsLoading(false);
        }
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cleanupFunction.current = true;
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
