import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router";
let logoutTimer;
export const useAuth = () => {
  let history = useHistory();
  const [token, setToken] = useState(null);
  const [teoIdToken, setTeoIdToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [teoId, setTeoId] = useState(null);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [profileId, setProfileId] = useState(null);
  const [profile, setProfile] = useState(null);
  const [upgrade, setUpgrade] = useState(null);
  const [cellphone, setCellphone] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [tokenExpiration, setTokenExpiration] = useState();
  const [lastLogin, setLastLogin] = useState(null);
  const [planFee, setPlanFee] = useState(null);
  const [onlinePayMethodId, setOnlinePayMethodId] = useState(null);
  const [manualTransfer, setManualTransfer] = useState(false);
  const [customerBusiness, setCustomerBusiness] = useState(null);
  const [is_collab, setIs_collab] = useState(null);
  const [productsPlan, setProductsPlan] = useState(null);
  const [userAdmin, setUserAdmin] = useState(null);

  const login = useCallback(
    (
      token,
      teoIdToken,
      userId,
      teoId,
      email,
      name,
      lastname,
      profileId,
      profile,
      upgrade,
      cellphone,
      photo,
      lastLogin,
      planFee,
      onlinePayMethodId,
      manualTransfer,
      customerBusiness,
      is_collab,
      productsPlan,
      userAdmin,
      expiration
    ) => {
      setToken(token);
      setTeoIdToken(teoIdToken);
      setUserId(userId);
      setTeoId(teoId);
      setEmail(email);
      setName(name);
      setLastname(lastname);
      setProfileId(profileId);
      setProfile(profile);
      setUpgrade(upgrade);
      setCellphone(cellphone);
      setPhoto(photo);
      setLastLogin(lastLogin);
      setPlanFee(planFee);
      setOnlinePayMethodId(onlinePayMethodId);
      setManualTransfer(manualTransfer);
      setCustomerBusiness(customerBusiness);
      setIs_collab(is_collab);
      setProductsPlan(productsPlan);
      setUserAdmin(userAdmin);
      const tokenExpiration =
        expiration || new Date(new Date().getTime() + 1000 * 60 * 60);
      setTokenExpiration(tokenExpiration);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          token: token,
          teoIdToken: teoIdToken,
          userId: userId,
          teoId: teoId,
          email: email,
          name: name,
          lastname: lastname,
          profileId: profileId,
          profile: profile,
          upgrade: upgrade,
          photo: photo,
          cellphone: cellphone,
          expiration: tokenExpiration.toISOString(),
          lastLogin: lastLogin,
          planFee: planFee,
          onlinePayMethodId: onlinePayMethodId,
          manualTransfer: manualTransfer,
          customerBusiness: customerBusiness,
          is_collab: is_collab,
          productsPlan: productsPlan,
          userAdmin: userAdmin,
        })
      );
    },
    []
  );

  const logout = useCallback((teoId) => {
    setToken(null);
    setTeoIdToken(null);
    setUserId(null);
    setTeoId(null);
    setEmail(null);
    setName(null);
    setLastname(null);
    setProfileId(null);
    setProfile(null);
    setUpgrade(null);
    setCellphone(null);
    setPhoto(null);
    setTokenExpiration(null);
    setLastLogin(null);
    setPlanFee(null);
    setOnlinePayMethodId(null);
    setManualTransfer(false);
    setCustomerBusiness(null);
    setIs_collab(null);
    setProductsPlan(null);
    setUserAdmin(null);
    localStorage.removeItem("userData");
    if (teoId) {
      window.location.href = `${process.env.REACT_APP_TEOID_URL}/logout?app=teopagos&teoId=${teoId}&redirect=${process.env.REACT_APP_TEOPAGOS_WP_URL}`;
    } else {
      window.location.href = `${
        process.env.REACT_APP_TEOID_LOGIN
      }&to=${window.location.pathname.replace("/", "")}`;
    }
  }, []);

  useEffect(() => {
    if (token && tokenExpiration) {
      const duration = tokenExpiration.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, duration);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpiration]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.token,
        storedData.teoIdToken,
        storedData.userId,
        storedData.teoId,
        storedData.email,
        storedData.name,
        storedData.lastname,
        storedData.profileId,
        storedData.profile,
        storedData.upgrade,
        storedData.cellphone,
        storedData.photo,
        storedData.lastLogin,
        storedData.planFee,
        storedData.onlinePayMethodId,
        storedData.manualTransfer,
        storedData.customerBusiness,
        storedData.is_collab,
        storedData.productsPlan,
        storedData.userAdmin,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  //upgraded
  const upgraded = (upgrade) => {
    // console.log("upgraded", upgrade);
    setUpgrade(upgrade);
    const storedData = JSON.parse(localStorage.getItem("userData"));
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token: storedData.token,
        teoIdToken: storedData.teoIdToken,
        userId: storedData.userId,
        teoId: storedData.teoId,
        email: storedData.email,
        name: storedData.name,
        lastname: storedData.lastname,
        profileId: storedData.profileId,
        profile: storedData.profile,
        upgrade: upgrade,
        photo: storedData.photo,
        cellphone: storedData.cellphone,
        expiration: new Date(storedData.expiration),
        lastLogin: storedData.lastLogin,
        planFee: storedData.planFee,
        onlinePayMethodId: storedData.onlinePayMethodId,
        manualTransfer: storedData.manualTransfer,
        customerBusiness: storedData.customerBusiness,
        is_collab: storedData.is_collab,
        productsPlan: storedData.productsPlan,
        userAdmin: storedData.userAdmin,
      })
    );
  };

  const updateAuth = (
    profileId,
    profile,
    planFee,
    onlinePayMethodId,
    manualTransfer,
    customerBusiness,
    is_collab,
    productsPlan
  ) => {
    // console.log("updateAuth print", profileId, profile, planFee, onlinePayMethodId, manualTransfer, customerBusiness, is_collab, productsPlan);
    setProfileId(profileId);
    setProfile(profile);
    setPlanFee(planFee);
    setOnlinePayMethodId(onlinePayMethodId);
    setManualTransfer(manualTransfer);
    setCustomerBusiness(customerBusiness);
    setIs_collab(is_collab);
    setProductsPlan(productsPlan);
    const storedData = JSON.parse(localStorage.getItem("userData"));
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token: storedData.token,
        teoIdToken: storedData.teoIdToken,
        userId: storedData.userId,
        teoId: storedData.teoId,
        email: storedData.email,
        name: storedData.name,
        lastname: storedData.lastname,
        profileId: profileId,
        profile: profile,
        planFee: planFee,
        upgrade: storedData.upgrade,
        photo: storedData.photo,
        cellphone: storedData.cellphone,
        expiration: new Date(storedData.expiration),
        lastLogin: storedData.lastLogin,
        onlinePayMethodId: storedData.onlinePayMethodId,
        manualTransfer: manualTransfer,
        customerBusiness: customerBusiness,
        is_collab: is_collab,
        productsPlan: productsPlan,
        userAdmin: storedData.userAdmin,
      })
    );
  };

  return {
    token,
    teoIdToken,
    userId,
    teoId,
    email,
    name,
    lastname,
    profileId,
    profile,
    upgrade,
    cellphone,
    photo,
    lastLogin,
    planFee,
    onlinePayMethodId,
    manualTransfer,
    customerBusiness,
    is_collab,
    productsPlan,
    userAdmin,
    login,
    logout,
    upgraded,
    updateAuth,
  };
};
