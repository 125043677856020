//menu mario ultimo

import React, { useEffect, useState, useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { AuthContext } from "../../context/auth-context.js";
// import Avatar from "../../components/commons/Avatar";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Badge,
} from "@material-ui/core";
//icon
import { ReactComponent as IconCobroRapido } from "../../assets/icons/icon-sidebar-cobro-rapido.svg";
import { ReactComponent as IconSuscripciones } from "../../assets/icons/icon-sidebar-calendar.svg";
import { ReactComponent as IconCatalogo } from "../../assets/icons/icon-sidebar-catalogo.svg";
import { ReactComponent as IconLinkNegocio } from "../../assets/icons/icon-sidebar-link-negocio.svg";
import { ReactComponent as IconMiNegocio } from "../../assets/icons/icon-sidebar-mi-negocio.svg";
import { ReactComponent as IconInformes } from "../../assets/icons/icon-sidebar-informe.svg";
import { ReactComponent as IconRetiros } from "../../assets/icons/icon-sidebar-retiro.svg";
import { ReactComponent as IconInicio } from "../../assets/icons/icon-sidebar-inicio.svg";
import { ReactComponent as IconNotificacion } from "../../assets/icons/icon-sidebar-notificacion.svg";
import { ReactComponent as IconHelp } from "../../assets/icons/icon-sidebar-help.svg";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

//styles
import useStyles from "../../styles/App.style.js";
import { makeStyles } from "@material-ui/core/styles";

const useStylesListaMenu = makeStyles((theme) => ({
  rootListaMenu: {
    width: 280,

    height: "calc(100% - 165px)",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
    transition: "width .5s ease",
  },
  rootListaMenuMin: {
    width: 120,
    marginTop: 165,
    height: "calc(100% - 165px)",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
    transition: "width .5s ease",
  },
  //CONTENEDOR SCROLL BAR
  listBox: {
    boxSizing: "border-box",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 165px)",
    //SCROLL BAR
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5, // Ancho del scrollbar para navegadores basados en WebKit
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Color transparente al principio
      borderRadius: 3,
      height: 50,
    },
    "&:hover::-webkit-scrollbar-thumb": {
      backgroundColor: "#37ED9A", // color al pasar el mouse
    },
    // Estilos específicos de Firefox
    scrollbarWidth: "thin", // Para navegadores que admiten la propiedad scrollbar-width (como Firefox)
    scrollbarColor: "transparent transparent",
    "&:hover": {
      scrollbarColor: "#37ED9A transparent",
    },
  },
  iconExpand: {
    fill: "white",
  },
  iconExpand2: {
    fill: "white",
    transform: "rotate(270deg)",
  },
  expandedBlock: {
    width: "270px",
    boxSizing: "border-box",
    padding: "5px 5px 5px 0",
    backgroundColor: "#001A3C",
    borderRadius: "5px",
    margin: "10px 0",
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },
  expandedBlockMin: {
    width: 120,
    boxSizing: "border-box",
    padding: "5px 5px 5px 0",
    backgroundColor: "#001A3C",
    borderRadius: "5px",
    margin: "10px 0",
    transition: "width .5s ease",
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },
  items: {
    width: "270px",
    boxSizing: "border-box",
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 10,
  },
  itemsMin: {
    width: 120,
    boxSizing: "border-box",
    paddingLeft: 35,
    marginBottom: 10,
  },
  titleSection: {
    padding: "0 0 0 8px",
    height: 40,
    marginBottom: 10,
  },
  subItems: {
    width: "270px",
    paddingLeft: "32px",
    minHeight: 40,
  },
  //seccion activa
  actived: {
    transition: "all .5s",
    paddingLeft: 8,
    paddingRight: 8,
    background: "#02224B",
    borderRadius: 5,
    "& span": {
      color: "#37ED9A",
    },
    "&:hover": {
      background: "#02224B",
      borderRadius: 5,
    },
    "& path": {
      stroke: "#37ED9A",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "translateZ(0)",
      transition: "width .3s ease",
    },
  },
  activedLink: {
    paddingLeft: 8,
    paddingRight: 8,
    background: "#02224B",
    borderRadius: 5,
    "& span": {
      color: "#37ED9A",
    },
    "&:hover": {
      background: "#02224B",
      borderRadius: 5,
    },
    "& path": {
      stroke: "#37ED9A",
      fill: "#37ED9A",
    },
  },
  activedMin: {
    paddingLeft: 8,
    paddingRight: 8,
    background: "#02224B",
    borderRadius: 5,
    "& span": {
      color: "#37ED9A",
    },
    "&:hover": {
      background: "#02224B",
      borderRadius: 5,
    },
    "& path": {
      stroke: "#37ED9A",
    },
    width: 40,
    height: 40,
    marginLeft: 25,
    marginBottom: 10,
  },
  activedLinkMin: {
    paddingLeft: 8,
    paddingRight: 8,
    background: "#02224B",
    borderRadius: 5,
    "& span": {
      color: "#37ED9A",
    },
    "&:hover": {
      background: "#02224B",
      borderRadius: 5,
    },
    "& path": {
      stroke: "#37ED9A",
      fill: "#37ED9A",
    },
    width: 40,
    height: 40,
    marginLeft: 25,
    marginBottom: 10,
  },
  activedItem: {
    borderRadius: 0,
    "& span": {
      color: "#37ED9A",
    },
  },
  activedItem2: {
    borderRadius: 0,
    borderLeft: "4px solid #37ED9A",
    height: 16,
  },

  listItemIcon: {
    minWidth: 24,
    height: 24,
    marginRight: 8,
  },
  listItemIcon2: {
    minWidth: 24,
    height: 24,
    marginRight: 8,
  },
  iconSidebar: {
    maxHeight: 24,
    maxWidth: 24,
  },
}));

const ListaMenu = (props) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const current = location.pathname;
  const classes = useStylesListaMenu();

  const [openCollapse, setOpenCollapse] = useState("");
  const { minimal, numberNotifications } = props;

  const handleCollapse = (collapseName) => {
    setOpenCollapse(openCollapse === collapseName ? "" : collapseName);
  };

  useEffect(() => {
    // cobros rapidos collapse
    console.log("current:", current.split("/")[1]);
    if (
      [
        "/mobile-cobrar-rapido",
        "/cobrar/link-listado",
        "/movimientos-cobro",
      ].includes(current)
    ) {
      // console.log("entramos");
      setOpenCollapse("CobrosRapidos");
    } else if (
      // suscripcion collapse
      [
        "/planes-suscripcion-crear",
        "/suscripciones-nuevo-plan",
        "/movimientos-cobro-subs",
        "/planes-suscripcion-listado",
        "/planes-suscripcion-listado/suscriptores",
        "/suscripciones",
        "/suscripciones-cobro",
        "/suscripciones-editar-plan",
        "/suscriptores",
        "/suscriptor-datos",
      ].includes(current) ||
      current.startsWith("/suscriptores-plan/")
    ) {
      setOpenCollapse("Suscripciones");
    } else if (
      // catalog collapse
      [
        "catalogo-cobro-producto",
        "movimientos-cobro-cata",
        "catalogo-lista",
        "catalogo-nuevo-producto",
        "catalogo-editar-producto",
        "catalogo",
        "catalogo-editar",
        "catalogo-compartir",
      ].includes(current.split("/")[1])
    ) {
      setOpenCollapse("Catalogo");
    } else if (
      // mi negocio collapse
      [
        "/requisitos",
        "/colaboradores-lista",
        "/catalogo-lista",
        "/plan",
      ].includes(current)
    ) {
      setOpenCollapse("Mi Negocio");
    } else if (
      // mi negocio collapse
      ["/informes/informes-financieros", "/movimientos-cobro-informe"].includes(
        current
      )
    ) {
      setOpenCollapse("Informes");
    } else if (
      // mi cuenta collapse
      ["/metodos-pago", "/datos-personales", "/security"].includes(current)
    ) {
      setOpenCollapse("Mi Cuenta");
    } else {
      setOpenCollapse("");
    }
  }, [current]);
  // console.log("minimal1:", minimal);

  return (
    <div
      className={!minimal ? classes.rootListaMenu : classes.rootListaMenuMin}
    >
      <List component="nav">
        <div>
          {/* ===== INICIO ===== */}
          <ListItem
            button
            component={NavLink}
            onClick={props.closeDrawer}
            to="/dashboard"
            className={
              current == "/dashboard" ||
              current == "/notificaciones" ||
              current == "/"
                ? !minimal
                  ? classes.actived
                  : classes.activedMin
                : !minimal
                ? classes.items
                : classes.itemsMin
            }
          >
            <ListItemIcon className={classes.listItemIcon}>
              <IconInicio min={minimal} />
            </ListItemIcon>
            {!minimal && (
              <ListItemText primary="Inicio" style={{ color: "white" }} />
            )}
          </ListItem>

          {/* ===== FIN INICIO ===== */}

          {/* ===== PROFILE BASIC ===== */}
          {auth.profileId === 1 ? (
            <ListItem
              button
              component={NavLink}
              to="/habilitar-ventas"
              className={
                current == "/habilitar-ventas"
                  ? !minimal
                    ? classes.actived
                    : classes.activedMin
                  : !minimal
                  ? classes.items
                  : classes.itemsMin
              }
            >
              <ListItemIcon className={classes.listItemIcon}>
                <IconMiNegocio />
              </ListItemIcon>
              {!minimal && (
                <ListItemText
                  primary="Crear negocio"
                  style={{ color: "white" }}
                />
              )}
            </ListItem>
          ) : (
            <>
              {/* ===== SECCION COBRAR ===== */}
              {!minimal ? (
                <ListItem className={classes.titleSection}>
                  <ListItemText primary="COBRAR" style={{ color: "white" }} />
                </ListItem>
              ) : (
                <ListItem
                  button
                  component={NavLink}
                  to="/mobile-cobrar-rapido"
                  className={
                    openCollapse == "CobrosRapidos"
                      ? !minimal
                        ? classes.actived
                        : classes.activedMin
                      : !minimal
                      ? classes.items
                      : classes.itemsMin
                  }
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <IconCobroRapido min={minimal} />
                  </ListItemIcon>
                </ListItem>
              )}

              {/* ===== COBRO RAPIDO ===== */}
              {!minimal && (
                <div
                  className={
                    openCollapse === "CobrosRapidos"
                      ? !minimal
                        ? classes.expandedBlock
                        : classes.expandedBlockMin
                      : ""
                  }
                >
                  <ListItem
                    button
                    onClick={() => handleCollapse("CobrosRapidos")}
                    className={!minimal ? classes.items : classes.itemsMin}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <IconCobroRapido
                        className={
                          !minimal
                            ? classes.iconSidebar
                            : classes.iconSidebarMin
                        }
                      />
                    </ListItemIcon>
                    {!minimal && (
                      <>
                        <ListItemText
                          primary="Cobros Rapidos"
                          style={{ color: "white" }}
                        />

                        {openCollapse === "CobrosRapidos" ? (
                          <ExpandLess className={classes.iconExpand} />
                        ) : (
                          <ExpandMore className={classes.iconExpand} />
                        )}
                      </>
                    )}
                  </ListItem>

                  {/* SUB MENU COBRO RAPIDO - NUEVO COBRO */}
                  {!minimal && (
                    <Collapse
                      in={openCollapse === "CobrosRapidos"}
                      timeout="auto"
                      unmountOnExit
                      component={NavLink}
                      onClick={props.closeDrawer}
                      to="/mobile-cobrar-rapido"
                    >
                      <List
                        component="div"
                        disablePadding
                        className={
                          current == "/mobile-cobrar-rapido" &&
                          classes.activedItem
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            className={
                              current == "/mobile-cobrar-rapido" &&
                              classes.activedItem2
                            }
                          />
                          <ListItem button className={classes.subItems}>
                            <ListItemText
                              primary="Nuevo Cobro"
                              style={{ color: "white" }}
                            />
                          </ListItem>
                        </div>
                      </List>
                    </Collapse>
                  )}

                  {/*  */}
                  {/* SUB MENU COBRO RAPIDO - LISTA DE LINKS */}
                  {!minimal && (
                    <Collapse
                      in={openCollapse === "CobrosRapidos"}
                      timeout="auto"
                      unmountOnExit
                      component={NavLink}
                      onClick={props.closeDrawer}
                      to="/cobrar/link-listado"
                    >
                      <List
                        component="div"
                        disablePadding
                        className={
                          current == "/cobrar/link-listado" &&
                          classes.activedItem
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            className={
                              current == "/cobrar/link-listado" &&
                              classes.activedItem2
                            }
                          />
                          <ListItem button className={classes.subItems}>
                            <ListItemText
                              primary="Listado"
                              style={{ color: "white" }}
                            />
                          </ListItem>
                        </div>
                      </List>
                    </Collapse>
                  )}

                  {/* SUB MENU COBRO RAPIDO - HISTORIAL DE COBROS */}
                  {!minimal && (
                    <Collapse
                      in={openCollapse === "CobrosRapidos"}
                      timeout="auto"
                      unmountOnExit
                      component={NavLink}
                      onClick={props.closeDrawer}
                      to="/movimientos-cobro"
                    >
                      <List
                        component="div"
                        disablePadding
                        className={
                          current == "/movimientos-cobro" && classes.activedItem
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            className={
                              current == "/movimientos-cobro" &&
                              classes.activedItem2
                            }
                          />

                          <ListItem button className={classes.subItems}>
                            <ListItemText
                              primary="Historial de cobros"
                              style={{ color: "white" }}
                            />
                          </ListItem>
                        </div>
                      </List>
                    </Collapse>
                  )}
                </div>
              )}
              {/* ===== FIN COBROS RAPIDOS ===== */}

              {/* ===== SUSCRIPCIONES ===== */}
              {minimal ? (
                <ListItem
                  button
                  component={NavLink}
                  to="/planes-suscripcion-listado"
                  className={
                    openCollapse == "Suscripciones"
                      ? !minimal
                        ? classes.actived
                        : classes.activedMin
                      : !minimal
                      ? classes.items
                      : classes.itemsMin
                  }
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <IconSuscripciones min={minimal} />
                  </ListItemIcon>
                </ListItem>
              ) : (
                <div
                  className={
                    openCollapse === "Suscripciones"
                      ? classes.expandedBlock
                      : ""
                  }
                >
                  <ListItem
                    button
                    onClick={() => handleCollapse("Suscripciones")}
                    className={classes.items}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <IconSuscripciones className={classes.iconSidebar} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Suscripciones"
                      style={{ color: "white" }}
                    />
                    {openCollapse === "Suscripciones" ? (
                      <ExpandLess className={classes.iconExpand} />
                    ) : (
                      <ExpandMore className={classes.iconExpand} />
                    )}
                  </ListItem>
                  {/* SUB MENU SUSCRIPCIONES  - NUEVO COBRO */}
                  <Collapse
                    in={openCollapse === "Suscripciones"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/suscripciones-cobro"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/suscripciones-cobro" && classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/suscripciones-cobro" &&
                            classes.activedItem2
                          }
                        />

                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary="Nuevo Cobro"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>

                  {/* SUB MENU SUSCRIPCIONES  - HISTORIAL DE COBROS */}
                  <Collapse
                    in={openCollapse === "Suscripciones"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/movimientos-cobro-subs"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/movimientos-cobro-subs" &&
                        classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/movimientos-cobro-subs" &&
                            classes.activedItem2
                          }
                        />

                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary=" Historial de cobros"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>

                  {/* SUB MENU SUSCRIPCIONES - PLANES DE SUSCRIPCION */}
                  <Collapse
                    in={openCollapse === "Suscripciones"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/suscripciones"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/suscripciones" && classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/suscripciones" && classes.activedItem2
                          }
                        />

                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary="Planes de Suscripción"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>

                  {/* SUB MENU SUSCRIPCIONES - SUSCRIPTORES*/}
                  <Collapse
                    in={openCollapse === "Suscripciones"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/suscriptores"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/suscriptores" && classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/suscriptores" && classes.activedItem2
                          }
                        />
                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary="Suscriptores"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>
                </div>
              )}
              {/* ===== FIN SUSCRIPCIONES ===== */}

              {/* ===== CATALOGO ===== */}
              {minimal ? (
                <ListItem
                  button
                  component={NavLink}
                  to="/catalogo"
                  className={
                    openCollapse == "Catalogo"
                      ? !minimal
                        ? classes.actived
                        : classes.activedMin
                      : !minimal
                      ? classes.items
                      : classes.itemsMin
                  }
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <IconCatalogo min={minimal} />
                  </ListItemIcon>
                </ListItem>
              ) : (
                <div
                  className={
                    openCollapse === "Catalogo" ? classes.expandedBlock : ""
                  }
                >
                  <ListItem
                    button
                    onClick={() => handleCollapse("Catalogo")}
                    className={classes.items}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <IconCatalogo className={classes.iconSidebar} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Catalogo"
                      style={{ color: "white" }}
                    />
                    {openCollapse === "Catalogo" ? (
                      <ExpandLess className={classes.iconExpand} />
                    ) : (
                      <ExpandMore className={classes.iconExpand} />
                    )}
                  </ListItem>

                  {/* SUB MENU CATALOGO - NUEVO COBRO */}
                  <Collapse
                    in={openCollapse === "Catalogo"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/catalogo-cobro-producto"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/catalogo-cobro-producto" &&
                        classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/catalogo-cobro-producto" &&
                            classes.activedItem2
                          }
                        />
                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary="Nuevo Cobro"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>

                  {/* SUB MENU CATALOGO - HISTORIAL DE COBROS */}
                  <Collapse
                    in={openCollapse === "Catalogo"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/movimientos-cobro-cata"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/movimientos-cobro-cata" &&
                        classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/movimientos-cobro-cata" &&
                            classes.activedItem2
                          }
                        />
                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary=" Historial de cobros"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>

                  {/* SUB MENU CATALOGO - CATALOGO*/}
                  <Collapse
                    in={openCollapse === "Catalogo"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    to="/catalogo"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={current == "/catalogo" && classes.activedItem}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/catalogo" && classes.activedItem2
                          }
                        />

                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary="Catalogo"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>
                </div>
              )}
              {/* ===== FIN CATALOGO ===== */}

              {/* =====  LINK DE NEGOCIO =====*/}
              <ListItem
                button
                component={NavLink}
                onClick={props.closeDrawer}
                to="/perfil-publico"
                className={
                  current == "/perfil-publico"
                    ? !minimal
                      ? classes.activedLink
                      : classes.activedLinkMin
                    : !minimal
                    ? classes.items
                    : classes.itemsMin
                }
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <IconLinkNegocio />
                </ListItemIcon>
                {!minimal && (
                  <ListItemText
                    primary="Link de Negocio"
                    style={{ color: "white" }}
                  />
                )}
              </ListItem>
              {/* ===== FIN LINK DE NEGOCIO  */}

              {/* ===== FIN SECCION COBRAR  */}

              {/* ===== SECCION ADMINISTRAR =====*/}
              {!minimal && (
                <ListItem className={classes.titleSection}>
                  <ListItemText
                    primary="ADMINISTRAR"
                    style={{ color: "white" }}
                  />
                </ListItem>
              )}

              {/* ===== MI NEGOCIO =====*/}
              {minimal ? (
                <ListItem
                  button
                  component={NavLink}
                  to="/requisitos"
                  className={
                    openCollapse == "Mi Negocio"
                      ? !minimal
                        ? classes.actived
                        : classes.activedMin
                      : !minimal
                      ? classes.items
                      : classes.itemsMin
                  }
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <IconMiNegocio />
                  </ListItemIcon>
                </ListItem>
              ) : (
                <div
                  className={
                    openCollapse === "Mi Negocio" ? classes.expandedBlock : ""
                  }
                >
                  <ListItem
                    button
                    onClick={() => handleCollapse("Mi Negocio")}
                    className={classes.items}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <IconMiNegocio className={classes.iconSidebar} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Mi Negocio"
                      style={{ color: "white" }}
                    />
                    {openCollapse === "Mi Negocio" ? (
                      <ExpandLess className={classes.iconExpand} />
                    ) : (
                      <ExpandMore className={classes.iconExpand} />
                    )}
                  </ListItem>

                  {/* SUB MENU MI NEGOCIO - DATOS DE NEGOCIO*/}
                  <Collapse
                    in={openCollapse === "Mi Negocio"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/requisitos"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/requisitos" && classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/requisitos" && classes.activedItem2
                          }
                        />
                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary="Datos de negocio"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>

                  {/* SUB MENU MI NEGOCIO - FACTURACION*/}
                  <Collapse
                    in={openCollapse === "Mi Negocio"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/plan"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={current == "/plan" && classes.activedItem}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={current == "/plan" && classes.activedItem2}
                        />
                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary="Facturación"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>

                  {/* SUB MENU MI NEGOCIO - COLABORADORES */}
                  <Collapse
                    in={openCollapse === "Mi Negocio"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/colaboradores-lista"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/colaboradores-lista" && classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/colaboradores-lista" &&
                            classes.activedItem2
                          }
                        />
                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary=" Colaboradores"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>
                </div>
              )}
              {/* ===== FIN MI NEGOCIO =====*/}

              {/* ===== INFORMES ===== */}
              {minimal ? (
                <ListItem
                  button
                  component={NavLink}
                  to="/informes/informes-financieros"
                  className={
                    openCollapse == "Informes"
                      ? !minimal
                        ? classes.actived
                        : classes.activedMin
                      : !minimal
                      ? classes.items
                      : classes.itemsMin
                  }
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <IconInformes />
                  </ListItemIcon>
                </ListItem>
              ) : (
                <div
                  className={
                    openCollapse === "Informes" ? classes.expandedBlock : ""
                  }
                >
                  <ListItem
                    button
                    onClick={() => handleCollapse("Informes")}
                    className={classes.items}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <IconInformes className={classes.iconSidebar} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Informes"
                      style={{ color: "white" }}
                    />
                    {openCollapse === "Informes" ? (
                      <ExpandLess className={classes.iconExpand} />
                    ) : (
                      <ExpandMore className={classes.iconExpand} />
                    )}
                  </ListItem>

                  {/* SUB MENU INFORMES - RESUMEN DE COBROS*/}
                  <Collapse
                    in={openCollapse === "Informes"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/movimientos-cobro-informe"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/movimientos-cobro-informe" &&
                        classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/movimientos-cobro-informe" &&
                            classes.activedItem2
                          }
                        />
                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary="Resumen de Cobros"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>

                  {/* SUB MENU INFORMES - INFORME FINANCIERO*/}
                  <Collapse
                    in={openCollapse === "Informes"}
                    timeout="auto"
                    unmountOnExit
                    component={NavLink}
                    onClick={props.closeDrawer}
                    to="/informes/informes-financieros"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        current == "/informes/informes-financieros" &&
                        classes.activedItem
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className={
                            current == "/informes/informes-financieros" &&
                            classes.activedItem2
                          }
                        />
                        <ListItem button className={classes.subItems}>
                          <ListItemText
                            primary="Informe Financiero"
                            style={{ color: "white" }}
                          />
                        </ListItem>
                      </div>
                    </List>
                  </Collapse>
                </div>
              )}
              {/* ===== FIN INFORMES ===== */}

              {/* ===== RETIROS =====*/}
              <ListItem
                style={{ paddingBottom: 20 }}
                button
                component={NavLink}
                onClick={props.closeDrawer}
                to="/retiros"
                className={
                  current == "/retiros"
                    ? !minimal
                      ? classes.actived
                      : classes.activedMin
                    : !minimal
                    ? classes.items
                    : classes.itemsMin
                }
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <IconRetiros className={classes.iconSidebar} />
                </ListItemIcon>
                {!minimal && (
                  <ListItemText primary="Retiros" style={{ color: "white" }} />
                )}
              </ListItem>
              {/* ===== FIN RETIROS =====*/}
              {/* ===== FIN SECCION ADMINISTRAR =====*/}
            </>
          )}
        </div>

        <div>
          <Divider style={{ background: "white", margin: "10px 20px" }} />

          {/* ===== SECCION CONFIGURACION =====*/}
          {/* ===== NOTIFICACIONES =====*/}
          <ListItem
            style={{ marginBottom: 0 }}
            button
            component={NavLink}
            onClick={props.closeDrawer}
            to="/notificaciones"
            className={
              current == "/notificaciones"
                ? !minimal
                  ? classes.actived
                  : classes.activedMin
                : !minimal
                ? classes.items
                : classes.itemsMin
            }
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Badge
                {...(numberNotifications > 0 && {
                  badgeContent: numberNotifications,
                })}
              >
                <IconNotificacion className={classes.iconSidebar} />
              </Badge>
            </ListItemIcon>
            {!minimal && (
              <ListItemText
                primary="Notificaciones"
                style={{ color: "white" }}
              />
            )}
          </ListItem>
          {/* ===== FIN NOTIFICACIONES =====*/}

          {/* ===== AYUDA ===== */}
          <ListItem
            style={{ marginBottom: 0 }}
            button
            component={NavLink}
            onClick={props.closeDrawer}
            to="/help"
            className={
              current == "/help"
                ? !minimal
                  ? classes.actived
                  : classes.activedMin
                : !minimal
                ? classes.items
                : classes.itemsMin
            }
          >
            <ListItemIcon className={classes.listItemIcon}>
              <IconHelp className={classes.iconSidebar} />
            </ListItemIcon>
            {!minimal && (
              <ListItemText primary="Ayuda" style={{ color: "white" }} />
            )}
          </ListItem>
          {/* =====FIN AYUDA ===== */}

          {/* ===== MI CUENTA ===== */}
          <div
            className={
              openCollapse === "Mi Cuenta"
                ? !minimal
                  ? classes.expandedBlock
                  : classes.expandedBlockMin
                : ""
            }
          >
            <ListItem
              button
              onClick={() => handleCollapse("Mi Cuenta")}
              className={!minimal ? classes.items : classes.itemsMin}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Avatar
                  alt="Remy Sharp"
                  src={
                    auth.photo ? auth.photo : "../../assets/icons/coverSub.png"
                  }
                  className={classes.iconSidebar}
                />
                {/* <Avatar
                    title={`${auth.name} ${auth.lastname || ""}`}
                    auth={auth}
                    img={auth.photo ? auth.photo : "/avatars/avatar-1.jpg"}
                    clase="primary"
                    types="complex"
                    className={classes.iconSidebar}
                  /> */}
              </ListItemIcon>
              {!minimal && (
                <>
                  <ListItemText
                    primary="Mi Cuenta"
                    style={{ color: "white" }}
                  />

                  {openCollapse === "Mi Cuenta" ? (
                    <ExpandLess className={classes.iconExpand} />
                  ) : (
                    <ExpandMore className={classes.iconExpand2} />
                  )}
                </>
              )}
            </ListItem>

            {/* SUB MENU MI CUENTA - DATOS PERSONALES */}
            {!minimal && (
              <Collapse
                in={openCollapse === "Mi Cuenta"}
                timeout="auto"
                unmountOnExit
                component={NavLink}
                onClick={props.closeDrawer}
                to="/datos-personales"
              >
                <List
                  component="div"
                  disablePadding
                  className={
                    current == "/datos-personales" && classes.activedItem
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={
                        current == "/datos-personales" && classes.activedItem2
                      }
                    />
                    <ListItem button className={classes.subItems}>
                      <ListItemText
                        primary="Datos Personales"
                        style={{ color: "white" }}
                      />
                    </ListItem>
                  </div>
                </List>
              </Collapse>
            )}

            {/* SUB MENU MI CUENTA - MIS TARJETAS */}
            {!minimal && (
              <Collapse
                in={openCollapse === "Mi Cuenta"}
                timeout="auto"
                unmountOnExit
                component={NavLink}
                onClick={props.closeDrawer}
                to="/metodos-pago"
              >
                <List
                  component="div"
                  disablePadding
                  className={current == "/metodos-pago" && classes.activedItem}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={
                        current == "/metodos-pago" && classes.activedItem2
                      }
                    />

                    <ListItem button className={classes.subItems}>
                      <ListItemText
                        primary="Mis Tarjetas"
                        style={{ color: "white" }}
                      />
                    </ListItem>
                  </div>
                </List>
              </Collapse>
            )}

            {/* SUB MENU MI CUENTA - SEGURIDAD */}
            {!minimal && (
              <Collapse
                in={openCollapse === "Mi Cuenta"}
                timeout="auto"
                unmountOnExit
                component={NavLink}
                onClick={props.closeDrawer}
                to="/security"
              >
                <List
                  component="div"
                  disablePadding
                  className={current == "/security" && classes.activedItem}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={current == "/security" && classes.activedItem2}
                    />

                    <ListItem button className={classes.subItems}>
                      <ListItemText
                        primary="Seguridad"
                        style={{ color: "white" }}
                      />
                    </ListItem>
                  </div>
                </List>
              </Collapse>
            )}

            {/* SUB MENU MI CUENTA - LOGOUT */}
            {!minimal && (
              <Collapse
                in={openCollapse === "Mi Cuenta"}
                timeout="auto"
                unmountOnExit
                onClick={() => auth.logout(auth.teoId)}
              >
                <List component="div" disablePadding>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ListItem button className={classes.subItems}>
                      <ListItemText
                        primary="Cerrar sesión"
                        style={{ color: "white" }}
                      />
                    </ListItem>
                  </div>
                </List>
              </Collapse>
            )}
          </div>
          {/* ===== FIN MI CUENTA ===== */}
          {/* ===== FIN SECCION CONFIGURACION =====*/}
        </div>
      </List>
    </div>
  );
};

export default ListaMenu;
