import React, {useContext} from "react";
import { AuthContext } from "../../context/auth-context";
import { Link as RouterLink, useLocation, useHistory, useParams } from "react-router-dom";

//Style
import { useMediaQuery } from "@material-ui/core/";
import useStyles from "../../styles/App.style.js";

export default function HeaderUser(props) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)"); // breakpoint para móviles aquí

  const goDash = () => {
    window.location.href = `/dashboard`;
  }

  return (
    <>
      <header className={classes.sectionHeader}>
        <div className={classes.containerHeader}>
          {/*===== NOMBRE EMPRESA =====*/}
          <div style={{ display: "flex" }}>
            <div className={classes.tabsIcon}>
              <img src="/avatars/avatar.png" alt="Teo Pagos" />
            </div>
            <div>
              <p
                className={classes.bodySM}
                style={{ color: "#02224B", margin: "5px 0px 5px 10px" }}
              >
                {props.companyName}
              </p>
            </div>
          </div>

          {/*===== NOMBRE USUARIO =====*/}
          <div>
            <p
              className={classes.bodyXSR}
              style={{ color: "#02224B", margin: "5px 0", textAlign: "right" }}
            >
              Hola, {auth.name} {auth?.lastname}
            </p>
            <p
              className={[classes.bodyXSB, classes.linksOnly].join(' ')}
              style={{ color: "#02224B", margin: "5px 0", textAlign: "right" }}
              onClick={goDash}
            >
              Mi cuenta
            </p>
          </div>
        </div>
      </header>
    </>
  );
}
