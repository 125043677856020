import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  token: null,
  teoIdToken: null,
  userId: null,
  teoId: null,
  email: null,
  name: null,
  lastname: null,
  profileId: null,
  profile: null,
  upgrade: null,
  cellphone: null,
  photo: null,
  lastLogin: null,
  planFee: null, 
  onlinePayMethodId: null,
  manualTransfer: false,
  customerBusiness: null,
  is_collab: null,
  productsPlan: null,
  userAdmin: null,
  login: () => {},
  logout: () => {},
  upgraded: () => {},
  updateAuth: () => {}
});
