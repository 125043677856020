import React, { Children, Suspense, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { AuthContext } from "./context/auth-context.js";
import { useAuth } from "./hooks/auth-hook";
import { useHttpClient } from "./hooks/http-hook";
//Components
import Header from "./components/commons/Header";
import HeaderLogo from "./components/commons/HeaderLogo";
import HeaderInvitado from "./components/commons/HeaderInvitado";
import HeaderUser from "./components/commons/HeaderUser";
import BottomNav from "./components/commons/BottomNav";
import Sidebar from "./components/commons/Sidebar";

import { makeStyles } from "@material-ui/core/styles";

import { Hidden, ThemeProvider, Grid, useMediaQuery } from "@material-ui/core";
import theme from "./theme";

//Style
import useStyles from "./styles/App.style.js";

const Home = React.lazy(() => import("./pages/Home"));
const HomeAlt = React.lazy(() => import("./pages/Home-alt"));
const Ds = React.lazy(() => import("./pages/Ds"));
const Guia = React.lazy(() => import("./pages/Guia"));
const Signin = React.lazy(() => import("./pages/Login"));
const SinginAlt = React.lazy(() => import("./pages/LoginAdmin"));

const Inicio = React.lazy(() => import("./pages/Inicio"));
const InicioAlt = React.lazy(() => import("./pages/Inicio-alt"));
const CambiarPass = React.lazy(() => import("./pages/CambiarPass"));
const DatosPersonales = React.lazy(() => import("./pages/DatosPersonales"));
const MetodosPago = React.lazy(() => import("./pages/MetodoPago/MetodosPago"));
const AgregarTarjeta = React.lazy(() =>
  import("./pages/MetodoPago/AgregarTarjeta")
);
const Comunicaciones = React.lazy(() => import("./pages/Comunicaciones"));
const Ayuda = React.lazy(() => import("./pages/Ayuda"));
const Notificaciones = React.lazy(() => import("./pages/Notificaciones"));
const MobileCuenta = React.lazy(() => import("./pages/MobileCuenta"));
const MobileNegocio = React.lazy(() => import("./pages/MobileNegocio"));
const MobileNuevo = React.lazy(() => import("./pages/MobileNuevo"));
const MobileCobrar = React.lazy(() => import("./pages/MobileCobrar"));
const MobileCobrarSub = React.lazy(() => import("./pages/MobileCobrarSub"));
const MobileCobrarRapido = React.lazy(() =>
  import("./pages/MobileCobrarRapido")
);
const MobileCobrarShare = React.lazy(() => import("./pages/MobileCobrarShare"));
const MobileSubShare = React.lazy(() => import("./pages/MobileSubShare"));
const MobileSub = React.lazy(() => import("./pages/MobileSub"));
const CobrarPlanSuscripcion = React.lazy(() =>
  import("./pages/CobrarPlanSuscripcion")
);
const MobileSubDetail = React.lazy(() => import("./pages/MobileSubDetail"));
const MobileSubList = React.lazy(() => import("./pages/MobileSubList"));
const MobilePlan = React.lazy(() => import("./pages/MobilePlan"));
const MobilePlanEdit = React.lazy(() => import("./pages/MobilePlanEdit"));

/*----------------Movimientos-----------------*/
/* const Movimientos = React.lazy(() => import("./pages/movimientos/Movimientos")); */
const MovimientosAlt = React.lazy(() =>
  import("./pages/movimientos/MovimientosAlt")
);
const DetallesCobro = React.lazy(() =>
  import("./pages/movimientos/DetalleCobro")
);
const DetallesPago = React.lazy(() =>
  import("./pages/movimientos/DetallePago")
);
const DetallesAcreditacion = React.lazy(() =>
  import("./pages/movimientos/DetallesMovimientoAcreditacion")
);
const HistorialCobros = React.lazy(() =>
  import("./pages/movimientos/HistorialCobros")
);
const HistorialPagos = React.lazy(() =>
  import("./pages/movimientos/HistorialPagos")
);
const MovimientosCobros = React.lazy(() =>
  import("./pages/movimientos/MovimientosCobros")
);
const MovimientosPagos = React.lazy(() =>
  import("./pages/movimientos/MovimientosPagos")
);
/*--------------------------------------------*/

/*----------------Mi negocio-----------------*/
const InformesFinancieros = React.lazy(() =>
  import("./pages/miNegocio/informes/InformesFinancieros")
);
const InformesFinancierosDetalle = React.lazy(() =>
  import("./pages/miNegocio/informes/InformesFinancierosDetalle")
);
const Acreditaciones = React.lazy(() =>
  import("./pages/miNegocio/acreditaciones/Acreditaciones")
);
const AcreditacionesDetalle = React.lazy(() =>
  import("./pages/miNegocio/acreditaciones/DetalleAcreditacion")
);
const InformesFinancierosAcreditacion = React.lazy(() =>
  import("./pages/miNegocio/informes/InformesFinancierosAcreditacion")
);
const DetalleAcreditacionInmediata = React.lazy(() =>
  import("./pages/miNegocio/informes/DetalleAcreditacionInmediata")
);
const DetalleAcreditacionAliquidar = React.lazy(() =>
  import("./pages/miNegocio/informes/DetalleAcreditacioAliquidar")
);
const DatosNegocio = React.lazy(() =>
  import("./pages/miNegocio/configuracion/DatosNegocio")
);
const ColaboradoresLista = React.lazy(() =>
  import("./pages/miNegocio/colaboradores/ColaboradoresLista")
);
const ColaboradoresDetalle = React.lazy(() =>
  import("./pages/miNegocio/colaboradores/ColaboradoresDetalle")
);
const ColaboradoresEditar = React.lazy(() =>
  import("./pages/miNegocio/colaboradores/ColaboradoresEditar")
);
const AceptarColaborador = React.lazy(() =>
  import("./pages/miNegocio/colaboradores/AceptarColaborador")
);
const CobrosConfiguracion = React.lazy(() =>
  import("./pages/miNegocio/configuracion/CobrosConfiguracion")
);
const Metricas = React.lazy(() =>
  import("./pages/miNegocio/informes/Metricas")
);
const Plan = React.lazy(() => import("./pages/miNegocio/plan/Plan"));
// Catalogo
const CatalogoLista = React.lazy(() =>
  import("./pages/miNegocio/catalogo/CatalogoLista")
);
const CatalogoDetalle = React.lazy(() =>
  import("./pages/miNegocio/catalogo/CatalogoDetalle")
);
const CatalogoAgregar = React.lazy(() =>
  import("./pages/miNegocio/catalogo/CatalogoAgregar")
);
const CatalogoEditar = React.lazy(() =>
  import("./pages/miNegocio/catalogo/CatalogoEditar")
);
const CatalogoCobro = React.lazy(() =>
  import("./pages/miNegocio/catalogo/CatalogoCobro")
);
const CatalogoCompra = React.lazy(() =>
  import("./pages/miNegocio/catalogo/CatalogoCompra")
);
const CatalogoCompras = React.lazy(() =>
  import("./pages/Catalogo/CatalogoCompras")
);
const CatalogoShare = React.lazy(() =>
  import("./pages/miNegocio/catalogo/CatalogoShare")
);
const CatalogoDetallePerfil = React.lazy(() =>
  import("./pages/miNegocio/catalogo/CatalogoDetallePerfil")
);
// Retiros
const Retiros = React.lazy(() => import("./pages/miNegocio/retiros/Retiros"));
const RetirosDetalle = React.lazy(() =>
  import("./pages/miNegocio/retiros/Detalle")
);
const Retirar = React.lazy(() => import("./pages/miNegocio/retiros/Retirar"));
const RetirarDetalle = React.lazy(() =>
  import("./pages/miNegocio/retiros/RetirarDetalle")
);
const RetirarConfirmar = React.lazy(() =>
  import("./pages/miNegocio/retiros/RetirarConfirmar")
);
/*--------------------------------------------*/

/*----------------Requisitos-----------------*/
const Requisitos = React.lazy(() =>
  import("./pages/miNegocio/requisitos/RequisitosHome")
);
const HabilitarVentas = React.lazy(() =>
  import("./pages/miNegocio/requisitos/HabilitarVentas")
);
const RequisitosLista = React.lazy(() =>
  import("./pages/miNegocio/requisitos/RequisitosLista")
);
const Requisito = React.lazy(() =>
  import("./pages/miNegocio/requisitos/Requisito")
);
const RequisitosCampos = React.lazy(() =>
  import("./pages/miNegocio/requisitos/RequisitosLista")
);
/*--------------------------------------------*/

/*---------------------Cobrar-----------------*/
const NuevoCobro = React.lazy(() => import("./pages/cobrar"));
const LinkCrear = React.lazy(() => import("./pages/cobrar/linkDePago/Crear"));
const CatalogoCrear = React.lazy(() => import("./pages/cobrar/catalogo/Crear"));
const CatalogoCrearDetalle = React.lazy(() =>
  import("./pages/cobrar/catalogo/CrearDetalle")
);
const SuscripcionCrear = React.lazy(() =>
  import("./pages/miNegocio/suscripcion/Crear")
);
const SuscripcionLista = React.lazy(() =>
  import("./pages/miNegocio/suscripcion/Lista")
);
const SuscripcionDetalle = React.lazy(() =>
  import("./pages/miNegocio/suscripcion/Detalle")
);
const SuscripcionEditar = React.lazy(() =>
  import("./pages/miNegocio/suscripcion/Editar")
);
const SuscripcionShare = React.lazy(() =>
  import("./pages/miNegocio/suscripcion/SuscripcionShare")
);
const SuscriptorLista = React.lazy(() =>
  import("./pages/miNegocio/suscripcion/ListaSuscriptores")
);
const DetalleSuscriptor = React.lazy(() =>
  import("./pages/miNegocio/suscripcion/DetalleSuscriptor")
);
const Historico = React.lazy(() => import("./pages/cobrar/historico"));
/*---------------------Cobrar/Links Pago-----------------*/
const LinksPago = React.lazy(() =>
  import("./pages/cobrar/linkDePago/LinksPago")
);
const LinksPagoConfirmacion = React.lazy(() =>
  import("./pages/cobrar/linkDePago/LinksPagoConfirmacion")
);
const LinksListado = React.lazy(() =>
  import("./pages/cobrar/linkDePago/Listado")
);
const LinksPagoEditar = React.lazy(() =>
  import("./pages/cobrar/linkDePago/LinksPagoEditar")
);
const DetalleLinksPago = React.lazy(() =>
  import("./pages/cobrar/linkDePago/DetalleLinkPago")
);
const CompartirLink = React.lazy(() =>
  import("./components/linksPago/CompartirLink")
);
/*--------------------------------------------*/
/*---------------------Cobrar/Catalogo-----------------*/
const CatalogoListado = React.lazy(() =>
  import("./pages/cobrar/catalogo/Listado")
);
const CatalogoItemNuevo = React.lazy(() =>
  import("./pages/cobrar/catalogo/ItemCrear")
);
const CatalogoItemEditar = React.lazy(() =>
  import("./pages/cobrar/catalogo/ItemEditar")
);
const CatalogoConfirmacion = React.lazy(() =>
  import("./pages/cobrar/catalogo/Confirmacion")
);
/*--------------------------------------------*/

/*----------------Perfil Público-----------------*/
const PerfilPublico = React.lazy(() =>
  import("./pages/miNegocio/perfilPublico/PerfilPublico")
);
/*--------------------------------------------*/

/*----------------Comprador Pago-----------------*/
const InfoPago = React.lazy(() => import("./pages/comprador/InfoPago.js"));
/*const InfoPago = React.lazy(() => import("./pages/comprador/InfoPago"));*/
const Tarjeta = React.lazy(() => import("./pages/comprador/Tarjeta"));
const TarjetaDebito = React.lazy(() =>
  import("./pages/comprador/TarjetaDebito")
);
const TigoMoney = React.lazy(() => import("./pages/comprador/TigoMoney"));
const BilleteraPersonal = React.lazy(() =>
  import("./pages/comprador/BilleteraPersonal")
);
const Zimple = React.lazy(() => import("./pages/comprador/Zimple"));
const Confirmacion = React.lazy(() => import("./pages/comprador/Confirmacion"));
const Pendiente = React.lazy(() => import("./pages/comprador/Pendiente"));
const Error = React.lazy(() => import("./pages/comprador/Error"));
const Rechazado = React.lazy(() => import("./pages/comprador/Rechazado"));
const PagoRechazado = React.lazy(() => import("./pages/comprador/PagoFail"));
const Perfil = React.lazy(() => import("./pages/comprador/Perfil"));
const RegistroTarjeta = React.lazy(() =>
  import("./pages/comprador/RegistroTarjeta")
);
/*----------------Catalogo Perfil Público-----------------*/
const CatalogoPerfil = React.lazy(() =>
  import("./pages/miNegocio/catalogo/CatalogoPerfil")
);
/*--------------------------------------------*/
/* =============== MEJORAS 2.0 ===============*/
/* !---------------- CHECKOUT INVITADO-----------------*/

const MejoraCheckout = React.lazy(() =>
  import("./pages/comprador/Checkout.js")
);

const MejoraInvitado = React.lazy(() => import("./pages/invitado/Invitado.js"));
const MejoraInvitadoPago = React.lazy(() =>
  import("./pages/invitado/InvitadoPago.js")
);
const MejoraInvitadoPagoPersonal = React.lazy(() =>
  import("./pages/invitado/InvitadoPagoPersonal.js")
);
const MejoraInvitadoPagoZimple = React.lazy(() =>
  import("./pages/invitado/InvitadoPagoZimple.js")
);

const MejoraInvitadoPagoTigo = React.lazy(() =>
  import("./pages/invitado/InvitadoPagoTigo.js")
);
const MejoraInvitadoPagoCredito = React.lazy(() =>
  import("./pages/invitado/InvitadoPagoCredito.js")
);
const MejoraInvitadoPagoDebito = React.lazy(() =>
  import("./pages/invitado/InvitadoPagoDebito.js")
);

/* !---------------- CHECKOUT USUARIO LOGUEADO-----------------*/
const MejoraUserPago = React.lazy(() => import("./pages/user/UserPago.js"));

const MejoraUserPagoQR = React.lazy(() => import("./pages/user/UserPagoQR.js"));
const MejoraUserPagoDebito = React.lazy(() =>
  import("./pages/user/UserPagoDebito.js")
);
const MejoraUserMethods = React.lazy(() =>
  import("./pages/user/UserMethods.js")
);
const MejoraUserPagoTarjeta = React.lazy(() =>
  import("./pages/user/UserPagoTarjeta.js")
);
const MejoraUserNuevaTarjeta = React.lazy(() =>
  import("./pages/user/UserNuevaTarjeta.js")
);

/* !---------------- CHECKOUT -----------------*/
const MejoraPagoSucces = React.lazy(() =>
  import("./pages/comprador/PagoSucces.js")
);
const MejoraPagoFail = React.lazy(() =>
  import("./pages/comprador/PagoFail.js")
);
const MejoraPagoTarjetaOtra = React.lazy(() =>
  import("./pages/comprador/PagoTarjetaOtra.js")
);

/* !---------------- NEGOCIO -----------------*/
const NegocioPerfil = React.lazy(() =>
  import("./pages/Negocio/NegocioPerfil.js")
);
const NegocioCatalogo = React.lazy(() =>
  import("./pages/Negocio/NegocioCatalogo.js")
);
const NegocioCatalogoProducto = React.lazy(() =>
  import("./pages/Negocio/NegocioCatalogoProducto.js")
);
const NegocioPlanes = React.lazy(() =>
  import("./pages/Negocio/NegocioPlanes.js")
);
const NegocioEnvioDinero = React.lazy(() =>
  import("./pages/Negocio/NegocioEnvioDinero.js")
);

/* !---------------- CATALOGO -----------------*/
const Catalogo = React.lazy(() => import("./pages/Catalogo/Catalogo.js"));
const CatalogoProducto = React.lazy(() =>
  import("./pages/Catalogo/CatalogoProducto.js")
);
const CatalogoCobro2 = React.lazy(() =>
  import("./pages/Catalogo/CatalogoCobro.js")
);
const CatalogoCobroProductoDetalle = React.lazy(() =>
  import("./pages/Catalogo/CatalogoCobroProductoDetalle.js")
);
const CatalogoCompartir = React.lazy(() =>
  import("./pages/Catalogo/CatalogoCompartir.js")
);

/* !---------------- SUSCRIPCIONES -----------------*/
const Suscripciones = React.lazy(() =>
  import("./pages/Suscripciones/Suscripciones.js")
);
const SuscripcionesNuevoPlan = React.lazy(() =>
  import("./pages/Suscripciones/SuscripcionesNuevoPlan.js")
);
const SuscripcionesEditarPlan = React.lazy(() =>
  import("./pages/Suscripciones/SuscripcionesEditarPlan.js")
);
const SuscripcionesCompartirPlan = React.lazy(() =>
  import("./pages/Suscripciones/SuscripcionesCompartirPlan.js")
);
const Suscriptores = React.lazy(() =>
  import("./pages/Suscripciones/Suscriptores.js")
);
const SuscriptoresDatos = React.lazy(() =>
  import("./pages/Suscripciones/SuscripcionesDatosSuscriptor.js")
);

/* !---------------- TEST MARIO -----------------*/
// const TestSidebar = React.lazy(() => import("./pages/TestSidebar.js"));

/* =============== FIN MEJORAS 2.0 ===============*/
const CatalogoEditarProducto = React.lazy(() =>
  import("./pages/Catalogo/CatalogoEditarProducto.js")
);

// Headerless pages
const headerless = [
  "catalogo",
  "catalogo-nuevo-producto",
  "catalogo-editar-producto",
  "catalogo-cobro-producto",
  "catalogo-compartir",
  "movimientos-cobro-cata",
  "catalogo-cobro-producto-detalle",
  "catalogo-share",
  "suscripciones",
  "suscripciones-nuevo-plan",
  "suscripciones-editar-plan",
  "planes-suscripcion-crear",
  "suscriptores",
  "suscripciones-cobro",
  "suscriptores-plan",
  "suscriptor-datos",
  "suscripciones-compartir-plan",
  "movimientos-cobro",
  "movimientos-cobro-subs",
  "movimientos-cobro-informe",
];

function App() {
  const {
    token,
    teoIdToken,
    userId,
    teoId,
    email,
    name,
    lastname,
    profileId,
    profile,
    upgrade,
    cellphone,
    photo,
    lastLogin,
    planFee,
    onlinePayMethodId,
    manualTransfer,
    customerBusiness,
    is_collab,
    productsPlan,
    userAdmin,
    login,
    logout,
    upgraded,
    updateAuth,
  } = useAuth();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const pathname = window.location.pathname;
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const [rows, setRows] = useState(false);
  const [numberNotifications, setNumberNotifications] = useState(null);
  const [last_rows, setLastRows] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [companyName, setCompanyName] = useState();
  const [minimal, setMinimal] = useState(false);

  const getNotifications = async () => {
    setErrorMsg(null);
    if (token) {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        };
        const notifications = await sendRequest(
          `${process.env.REACT_APP_TEOPAGOS_URI}/user/my_notifications/`,
          "GET",
          null,
          headers
        );
        if (notifications) {
          setRows(notifications.rows);
          // console.log(customerBusiness, "customerBusiness");
          // console.log(
          //   notifications.data_user.customer_business,
          //   "customerBusinessApi"
          // );
          if (
            notifications.data_user.profileId !== profileId ||
            (notifications.data_user.customer_business &&
              notifications.data_user.customer_business.pending_contact !==
                customerBusiness.pending_contact)
          ) {
            console.log(
              "entro aca porque se actualizo perfil de usuario o se solicito habilitar cuenta"
            );
            updateAuth(
              notifications.data_user.profileId,
              notifications.data_user.profile,
              notifications.data_user.planFee,
              notifications.data_user.onlinePayMethodId,
              notifications.data_user.manualTransfer,
              notifications.data_user.customer_business,
              notifications.data_user.is_collab,
              notifications.data_user.products_plan
            );
          }
          let new_notifications = notifications.rows.filter(
            (row) => row.status === "new"
          );
          if (new_notifications.length > 5)
            setLastRows(new_notifications.slice(0, 5));
          else setLastRows(new_notifications);
          setNumberNotifications(new_notifications.length);
        } else {
          setErrorMsg(notifications.errors.message);
        }
      } catch (e) {
        console.log("error:");
        console.log(e);
        setErrorMsg("Ha ocurrido un error.");
      }
    }
  };

  useEffect(() => {
    getNotifications(rows);
  }, [token]);

  useEffect(() => {
    setCompanyName(localStorage.getItem("companyName"));
    let setMin = localStorage.getItem("minimal");
    setMinimal(JSON.parse(setMin));
    if (isMobile) {
      // console.log("es mobile cambiamos el minimal");
      // localStorage.setItem("minimal", false);
      setMinimal(false);
    }
  }, [localStorage, isMobile]);

  const handleCompanyName = (name) => {
    localStorage.setItem("companyName", name);
    setCompanyName(name);
  };

  const handleMinimal = (min) => {
    localStorage.setItem("minimal", min);
    setMinimal(min);
  };

  const onRemove = (notification) => {
    const exist = last_rows.find((row) => row.id === notification);
    if (exist) {
      let aux_rows = rows.filter((row) => row.id !== notification);
      setLastRows(aux_rows.length > 5 ? aux_rows.slice(0, 5) : aux_rows);
      setNumberNotifications(numberNotifications - 1);
    }
  };

  const updateRows = (newRows) => {
    setRows(newRows);
    let rows_news = newRows.filter((row) => row.status === "new");
    setLastRows(rows_news.length > 5 ? rows_news.slice(0, 5) : rows_news);
    setNumberNotifications(rows_news.length);
  };

  const HiddenSidebar = (
    <>
      <Hidden xsDown>
        <Sidebar
          variant="permanent"
          open={open}
          setMinimal={handleMinimal.bind(this)}
          numberNotifications={numberNotifications}
        />
      </Hidden>
      <Hidden smUp>
        <Sidebar
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          setMinimal={handleMinimal.bind(this)}
          numberNotifications={numberNotifications}
        />
      </Hidden>
    </>
  );

  let routes;
  if (token) {
    routes = (
      <>
        <Switch>
          {/* MEJORAS NEGOCIO */}
          <Route path="/negocio-perfil/:business_username" exact>
            <NegocioPerfil />
          </Route>
          {/* MEJORA CHECKOUT USUARIO */}
          {/* <Route path="/mejoras-user-pago" exact>
            <MejoraUserPago />
          </Route> */}
          {/* <Route path="/mejoras-user-pago-qr" exact>
            <MejoraUserPagoQR />
          </Route>
          <Route path="/mejoras-pago-succes" exact>
            <MejoraPagoSucces />
          </Route>
          <Route path="/mejoras-pago-fail" exact>
            <MejoraPagoFail />
          </Route>
          <Route path="/mejoras-user-pago-debito" exact>
            <MejoraUserPagoDebito />
          </Route>
          <Route path="/mejoras-user-pago-tarjeta" exact>
            <MejoraUserPagoTarjeta />
          </Route>
          <Route path="/mejoras-user-pago-nueva-tarjeta" exact>
            <MejoraUserNuevaTarjeta />
          </Route>
          <Route path="/mejoras-pago-tarjeta-otra" exact>
            <MejoraPagoTarjetaOtra />
          </Route> */}
          {/* working */}
          <Route path="/pago/:code/:userid?" exact>
            <MejoraUserPago
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>

          <Route path="/tarjetas" exact>
            <MejoraUserMethods />
          </Route>
          <Route path="/agregar-tarjeta" exact>
            <MejoraUserNuevaTarjeta />
          </Route>
          <Route path="/pago-user-credito" exact>
            <MejoraInvitadoPagoCredito />
          </Route>
          <Route path="/pago-user-debito" exact>
            <MejoraInvitadoPagoDebito />
          </Route>
          <Route path="/pago-user-personal" exact>
            <MejoraInvitadoPagoPersonal />
          </Route>
          <Route path="/pago-user-zimple" exact>
            <MejoraInvitadoPagoZimple />
          </Route>
          <Route path="/pago-user-tigo" exact>
            <MejoraInvitadoPagoTigo />
          </Route>
          <Route path="/pago-exitoso/:code?" exact>
            <MejoraPagoSucces />
          </Route>
          <Route path="/pago-fallido/:code?" exact>
            <MejoraPagoFail />
          </Route>
          {/* END MEJORA CHECKOUT INVITADO */}
          <Route
            path={["/", "/home", "/dashboard"]}
            exact
            render={() =>
              is_collab && is_collab.status === "Pendiente" ? (
                <Redirect to="/aceptar_colaborador" />
              ) : /* <Redirect to="/dashboard" /> */
              profileId == 1 ? (
                <InicioAlt />
              ) : (
                <Inicio />
              )
            }
          />
          <Route
            path="/dashboard"
            exact
            component={profileId == 1 ? InicioAlt : Inicio}
          />
          <Route
            path="/aceptar_colaborador"
            exact
            component={AceptarColaborador}
          />
          {/* COBRAR */}
          <Route
            path="/cobrar/nuevo"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <NuevoCobro />
              )
            }
          />
          <Route
            path="/cobrar/nuevo-link"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <LinkCrear />
              )
            }
          />
          <Route
            path="/cobrar/nuevo-catalogo"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <CatalogoCrear />
              )
            }
          />
          <Route
            path="/cobrar/nuevo-catalogo-detalle"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <CatalogoCrearDetalle />
              )
            }
          />
          <Route
            path="/cobrar/link-listado"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <LinksListado />
              )
            }
          />

          <Route
            path="/cobrar/catalogo/item-nuevo"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <CatalogoItemNuevo />
              )
            }
          />
          <Route
            path="/cobrar/catalogo/item-editar/:code"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <CatalogoItemEditar />
              )
            }
          />
          <Route
            path="/cobrar/catalogo-listado"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <CatalogoListado />
              )
            }
          />
          <Route
            path="/cobrar/catalogo-confirmacion"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <CatalogoConfirmacion />
              )
            }
          />
          <Route
            path="/planes-suscripcion-crear"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <SuscripcionCrear />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          <Route
            path="/planes-suscripcion-editar/:code"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <SuscripcionEditar />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          <Route
            path="/planes-suscripcion-listado/:action?"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <SuscripcionLista />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          <Route
            path="/planes-suscripcion/:code"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <SuscripcionDetalle />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          <Route
            path="/planes-suscripcion/:code/suscriptores"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <SuscriptorLista />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          <Route
            path="/planes-suscripcion/:code/compartir"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <SuscripcionShare />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          <Route
            path="/planes-suscripcion/suscriptor/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <DetalleSuscriptor />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          <Route
            path="/cobrar/historico"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <Historico />
              )
            }
          />
          {/* /COBRAR */}
          <Route
            path="/compartir-link"
            exact
            render={() => <CompartirLink />}
            //component={profileId == 1 ? Requisitos : }
          />
          <Route
            path="/movimientos-cobro"
            exact
            render={() =>
              profileId == 1 ? (
                <MovimientosAlt />
              ) : (
                <HistorialCobros type="link" />
              )
            }
            // component={profileId == 1 ? MovimientosAlt : HistorialCobros}
          />
          <Route
            path="/movimientos-cobro-subs"
            exact
            render={() =>
              profileId == 1 ? (
                <MovimientosAlt />
              ) : (
                <HistorialCobros type="subscription" />
              )
            }
            // component={profileId == 1 ? MovimientosAlt : HistorialCobros}
          />
          <Route
            path="/movimientos-cobro-cata"
            exact
            render={() =>
              profileId == 1 ? (
                <MovimientosAlt />
              ) : (
                <HistorialCobros type="catalog" />
              )
            }
            // component={profileId == 1 ? MovimientosAlt : HistorialCobros}
          />
          <Route
            path="/movimientos-cobro-informe"
            exact
            render={() =>
              profileId == 1 ? (
                <MovimientosAlt />
              ) : (
                <HistorialCobros type="link" />
              )
            }
            // component={profileId == 1 ? MovimientosAlt : HistorialCobros}
          />
          <Route
            path="/movimientos-pago"
            exact
            component={profileId == 1 ? MovimientosAlt : HistorialPagos}
          />
          <Route
            path="/movimientos-cobro/detalle/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <DetallesCobro />
              )
            }
            // component={profileId == 1 ? Requisitos : }
          />
          <Route
            path="/movimientos-pago/detalle/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <DetallesPago />
              )
            }
            //component={profileId == 1 ? Requisitos : DetallesPago}
          />
          <Route
            path="/movimientos/detalles-acreditacion/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <DetallesAcreditacion />
              )
            }
          />
          <Route
            path="/informes/informes-financieros"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <InformesFinancieros />
              )
            }
            //component={profileId == 1 ? Requisitos : InformesFinancieros}
          />
          <Route
            path="/informes/informes-financieros-subs"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <InformesFinancieros />
              )
            }
            //component={profileId == 1 ? Requisitos : InformesFinancieros}
          />
          <Route
            path="/informes/informes-financieros-cata"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <InformesFinancieros />
              )
            }
            //component={profileId == 1 ? Requisitos : InformesFinancieros}
          />
          <Route
            path="/informes/informes-detalle/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <InformesFinancierosDetalle />
              )
            }
            //component={profileId == 1 ? Requisitos : InformesFinancierosDetalle}
          />
          <Route
            path="/informes/informes-acreditacion/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <DetallesAcreditacion />
              )
            }
            // component={
            //   profileId == 1 ? Requisitos : InformesFinancierosAcreditacion
            // }
          />
          <Route
            path="/acreditaciones"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <Acreditaciones />
              )
            }
            // component={
            //   profileId == 1 ? Requisitos : InformesFinancierosAcreditacion
            // }
          />
          <Route
            path="/acreditaciones/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <AcreditacionesDetalle />
              )
            }
            // component={
            //   profileId == 1 ? Requisitos : DetalleAcreditacionInmediata
            // }
          />
          <Route
            path="/acreditaciones/inmediata/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <DetalleAcreditacionInmediata />
              )
            }
            // component={
            //   profileId == 1 ? Requisitos : DetalleAcreditacionInmediata
            // }
          />
          <Route
            path="/acreditaciones/a-liquidar/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <DetalleAcreditacionAliquidar />
              )
            }
            // component={
            //   profileId == 1 ? Requisitos : DetalleAcreditacionAliquidar
            // }
          />
          <Route
            path="/habilitar-ventas"
            exact
            render={() => <HabilitarVentas />}
          />
          <Route
            path="/datos-negocio"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <DatosNegocio />
              )
            }
            //component={profileId == 1 ? Requisitos : DatosNegocio}
          />
          <Route
            path="/metricas"
            exact
            render={() => <Metricas />}
            //component={profileId == 1 ? Requisitos : Metricas}
          />
          <Route
            path={"/requisitos"}
            exact
            component={
              profileId == 1 &&
              (!customerBusiness || !customerBusiness.pending_contact)
                ? HabilitarVentas
                : upgrade == true || profileId == 4
                ? RequisitosLista
                : Requisitos
            }
          />
          <Route path={"/requisito/:id"} exact render={() => <Requisito />} />
          <Route
            path={"/plan"}
            exact
            render={() =>
              profileId == 1 ? <Redirect to="/habilitar-ventas" /> : <Plan />
            }
          />
          <Route path="/confirmar-pago/:code?" exact>
            <Confirmacion />
          </Route>
          <Route
            path="/informacion-pago"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <InfoPago />
              )
            }
            //component={profileId == 1 ? Requisitos : InfoPago}
          />
          <Route path="/tarjeta" exact>
            <Tarjeta />
          </Route>
          <Route path="/tarjeta-debito" exact>
            <TarjetaDebito />
          </Route>
          <Route path="/tigo-money" exact>
            <TigoMoney />
          </Route>
          <Route path="/confirmacion" exact>
            <Confirmacion />
          </Route>
          <Route path="/pendiente" exact>
            <Pendiente />
          </Route>
          <Route path="/error" exact>
            <Error />
          </Route>
          <Route path="/rechazado" exact>
            <Rechazado />
          </Route>
          <Route path="/billetera-personal" exact>
            <BilleteraPersonal />
          </Route>
          <Route path="/mobile-cuenta" exact>
            <MobileCuenta />
          </Route>
          <Route path="/mobile-negocio" exact>
            <MobileNegocio />
          </Route>
          <Route path="/mobile-nuevo" exact>
            <MobileNuevo />
          </Route>
          <Route path="/mobile-cobrar" exact>
            <MobileCobrar />
          </Route>
          <Route path="/mobile-cobrar-nuevo" exact>
            <MobileCobrarSub />
          </Route>
          <Route
            path="/mobile-cobrar-rapido/:code?"
            exact
            render={() =>
              productsPlan &&
              productsPlan.filter(
                (product) => product.method.name == "Cobro Rápido"
              ).length > 0 ? (
                <MobileCobrarRapido />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          <Route path="/mobile-cobrar-share/:code?" exact>
            <MobileCobrarShare />
          </Route>
          <Route path="/mobile-suscripcion" exact>
            <MobileSub />
          </Route>
          <Route
            path="/mobile-cobrar-suscripcion/:action?"
            exact
            render={() =>
              productsPlan &&
              productsPlan.filter(
                (product) => product.method.name == "Suscripción"
              ).length > 0 ? (
                <CobrarPlanSuscripcion />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          <Route
            path="/mobile-cobrar-suscripcion-subs"
            exact
            render={() =>
              productsPlan &&
              productsPlan.filter(
                (product) => product.method.name == "Suscripción"
              ).length > 0 ? (
                <CobrarPlanSuscripcion />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          <Route
            path="/mobile-cobrar-suscripcion/compartir/:code?"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <SuscripcionShare />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          <Route
            path="/mobile-cobrar-suscripcion/editar/:code?"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <SuscripcionEditar />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          <Route
            path="/mobile-cobrar-suscripcion/:code/suscriptores"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <SuscriptorLista />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />

          <Route
            path="/mobile-cobrar-suscripcion/suscriptor/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Suscripción"
                ).length > 0 ? (
                <DetalleSuscriptor />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          />
          {/* CATALOGO */}
          <Route
            path="/catalogo-lista"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Catálogo"
                ).length > 0 ? (
                <CatalogoLista />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          <Route
            path="/catalogo-detalle/:code"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Catálogo"
                ).length > 0 ? (
                <CatalogoDetalle />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          <Route
            path="/catalogo-agregar"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Catálogo"
                ).length > 0 ? (
                <CatalogoAgregar />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          <Route
            path="/catalogo-editar/:code"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Catálogo"
                ).length > 0 ? (
                <CatalogoEditar />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          <Route
            path="/catalogo-cobro"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Catálogo"
                ).length > 0 ? (
                <CatalogoCobro />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          <Route
            path="/catalogo-compra"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Catálogo"
                ).length > 0 ? (
                <CatalogoCompra />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          <Route
            path="/catalogo-share"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : productsPlan &&
                productsPlan.filter(
                  (product) => product.method.name == "Catálogo"
                ).length > 0 ? (
                <CatalogoShare />
              ) : (
                <Redirect to="/mobile-cobrar" />
              )
            }
          ></Route>
          {/* RETIROS */}
          <Route path="/retiros" exact>
            <Retiros />
          </Route>
          <Route path="/retiros-detalle/:id" exact>
            <RetirosDetalle />
          </Route>
          <Route path="/retirar" exact>
            <Retirar />
          </Route>
          <Route path="/retirar-detalle" exact>
            <RetirarDetalle />
          </Route>
          <Route path="/retirar-confirmar" exact>
            <RetirarConfirmar />
          </Route>
          {/* END RETIROS */}
          <Route path="/zimple" exact>
            <Zimple />
          </Route>
          <Route
            path="/link-pago"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <LinksPago />
              )
            }
            //component={profileId == 1 ? Requisitos : LinksPago}
          />
          {/* <Route
            path="/cobrar/items/link-crear"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <LinksPagoCrear />
              )
            }
            //component={profileId == 1 ? Requisitos : LinksPagoCrear}
          /> */}
          <Route
            path="/link-confirmacion"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <LinksPagoConfirmacion />
              )
            }
            //component={profileId == 1 ? Requisitos : LinksPagoConfirmacion}
          />
          <Route
            path="/links-listado"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <LinksListado />
              )
            }
            //component={profileId == 1 ? Requisitos : LinksListado}
          />
          <Route
            path="/link-editar/:code"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <LinksPagoEditar />
              )
            }
            //component={profileId == 1 ? Requisitos : LinksPagoEditar}
          />
          {/* solicitar pago */}
          {/* <Route
            path="/solicitar-pago"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <SolicitarPago />
              )
            }
          /> */}
          {/* <Route
            path="/solicitar-pago-crear"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <SolicitarPagoCrear />
              )
            }
          /> */}
          {/* <Route
            path="/solicitar-pago-confirmacion"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <SolicitarPagoConfirmacion />
              )
            }
          /> */}
          {/* <Route
            path="/solicitar-pago-listado"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <SolicitarPagoListado />
              )
            }
          /> */}
          {/* <Route
            path="/solicitar-pago-editar/:code"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <SolicitarPagoEditar />
              )
            }
          /> */}
          {/* solicitar pago */}
          <Route
            path="/colaboradores-lista"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <ColaboradoresLista />
              )
            }
            // component={profileId == 1 ? Requisitos : }
          />
          <Route
            path="/colaboradores-detalle/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <ColaboradoresDetalle />
              )
            }
            //component={profileId == 1 ? Requisitos : ColaboradoresDetalle}
          />
          <Route
            path="/colaboradores-editar"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <ColaboradoresEditar />
              )
            }
            //component={profileId == 1 ? Requisitos : ColaboradoresEditar}
          />
          <Route
            path="/cobros-configuracion"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <CobrosConfiguracion />
              )
            }
            //component={profileId == 1 ? Requisitos : CobrosConfiguracion}
          />
          <Route
            path="/cobrar/links-pago/:method/:id"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <DetalleLinksPago />
              )
            }
            //component={profileId == 1 ? Requisitos : DetalleLinksPago}
          />
          <Route path="/confirmar-pago/:code?" exact component={Confirmacion} />
          <Route path="/home-alt" exact component={HomeAlt}>
            <HomeAlt />
          </Route>
          <Route path="/security" exact>
            <CambiarPass />
          </Route>
          <Route path="/help" exact>
            <Ayuda />
          </Route>
          <Route path="/datos-personales" exact>
            <DatosPersonales />
          </Route>
          <Route path="/metodos-pago" exact>
            <MetodosPago />
          </Route>
          <Route path="/metodos-pago/agregar" exact>
            <AgregarTarjeta />
          </Route>
          <Route path="/comunicaciones" exact>
            <Comunicaciones />
          </Route>
          <Route path="/notificaciones" exact>
            <Notificaciones
              numberNotifications={numberNotifications}
              onRemove={onRemove}
              rows={rows}
              updateRows={updateRows}
            />
          </Route>
          <Route path="/informes-acreditacion" exact>
            <InformesFinancierosAcreditacion />
          </Route>
          {/* <Route path="/requisitosOld/:id" exact>
            <RequisitosCamposOld />
          </Route> */}
          <Route path="/perfil/:business_username" exact>
            <Perfil />
          </Route>
          <Route path="/perfil/:business_username/catalogo" exact>
            <CatalogoPerfil />
          </Route>
          <Route path="/registro-tarjeta" exact>
            <RegistroTarjeta />
          </Route>
          <Route path="/signin" exact>
            <Signin />
          </Route>
          <Route
            path="/perfil-publico"
            exact
            render={() =>
              profileId == 1 ? (
                <Redirect to="/habilitar-ventas" />
              ) : (
                <PerfilPublico />
              )
            }
          />
          {/* MEJORA CHECKOUT USUARIO */}
          <Route path="/mejoras-user-pago" exact>
            <MejoraUserPago />
          </Route>
          <Route path="/mejoras-user-pago-qr" exact>
            <MejoraUserPagoQR />
          </Route>
          <Route path="/mejoras-pago-succes" exact>
            <MejoraPagoSucces />
          </Route>
          <Route path="/mejoras-pago-fail" exact>
            <MejoraPagoFail />
          </Route>
          <Route path="/mejoras-user-pago-debito" exact>
            <MejoraUserPagoDebito />
          </Route>
          <Route path="/mejoras-user-pago-tarjeta" exact>
            <MejoraUserPagoTarjeta />
          </Route>
          <Route path="/mejoras-user-pago-nueva-tarjeta" exact>
            <MejoraUserNuevaTarjeta />
          </Route>
          <Route path="/mejoras-pago-tarjeta-otra" exact>
            <MejoraPagoTarjetaOtra />
          </Route>
          {/*===== FIN MEJORA CHECKOUT USUARIO =====*/}

          {/* MEJORA CATALOGO */}
          <Route path="/catalogo" exact>
            <Catalogo />
          </Route>
          <Route path="/catalogo-nuevo-producto" exact>
            <CatalogoProducto />
          </Route>
          <Route path="/catalogo-cobro-producto" exact>
            <CatalogoCobro2 />
          </Route>
          <Route path="/catalogo-cobro-producto-detalle" exact>
            <CatalogoCobroProductoDetalle />
          </Route>
          <Route path="/catalogo-editar-producto/:id" exact>
            <CatalogoEditarProducto />
          </Route>
          <Route path="/catalogo-compartir" exact>
            <CatalogoCompartir />
          </Route>

          {/* MEJORA SUSCRIPCIONES */}
          <Route path="/suscripciones" exact>
            <Suscripciones />
          </Route>
          <Route path="/suscripciones-cobro" exact>
            <Suscripciones />
          </Route>
          <Route path="/suscripciones-nuevo-plan" exact>
            <SuscripcionesNuevoPlan />
          </Route>
          <Route path="/suscripciones-editar-plan/:code" exact>
            <SuscripcionesEditarPlan />
          </Route>
          <Route path="/suscripciones-compartir-plan/:code" exact>
            <SuscripcionesCompartirPlan />
            {/* <SuscripcionShare /> */}
          </Route>
          <Route path="/suscriptores" exact>
            <Suscriptores />
          </Route>
          <Route path="/suscriptores-plan/:code" exact>
            <Suscriptores />
          </Route>
          <Route path="/suscriptor-datos/:code" exact>
            <SuscriptoresDatos />
          </Route>

          {/*===== FIN MEJORA CHECKOUT USUARIO =====*/}

          <Redirect to="/suscripciones" />

          {/* <Route>
            <Redirect to="/" />
          </Route> */}
        </Switch>
      </>
    );
  } else {
    routes = (
      <>
        <Switch>
          {/* MEJORAS NEGOCIO */}
          <Route path="/negocio-perfil/:business_username" exact>
            <NegocioPerfil
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>

          {/* MEJORA CHECKOUT INVITADO */}
          <Route path="/pago/:code/:userid?" exact>
            <MejoraCheckout
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/pago-invitado/:code" exact>
            <MejoraInvitado
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/pago-invitado-metodo/:code" exact>
            <MejoraInvitadoPago
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/pago-invitado-credito/:code?" exact>
            <MejoraInvitadoPagoCredito
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/pago-invitado-debito/:code?" exact>
            <MejoraInvitadoPagoDebito
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/pago-invitado-personal/:code?" exact>
            <MejoraInvitadoPagoPersonal
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/pago-invitado-zimple/:code?" exact>
            <MejoraInvitadoPagoZimple
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/pago-invitado-tigo/:code?" exact>
            <MejoraInvitadoPagoTigo
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/pago-exitoso/:code?" exact>
            <MejoraPagoSucces />
          </Route>
          <Route path="/pago-fallido/:code?" exact>
            <MejoraPagoFail />
          </Route>
          {/* <Route path="/mejoras-pago-tarjeta-otra" exact>
            <MejoraPagoTarjetaOtra />
          </Route> */}
          {/* MEJORA CHECKOUT INVITADO */}
          {/* ======================== */}

          {/* MEJORA CHECKOUT USUARIO */}
          {/* ======================== */}
          <Route path={["/", "/home"]} exact component={Home} />
          <Route path="/signin" exact>
            <Signin />
          </Route>
          <Route path="/signin-alt" exact>
            <SinginAlt />
          </Route>
          <Route path="/ds" exact>
            <Ds />
          </Route>
          <Route path="/perfil/:business_username" exact>
            <Perfil />
          </Route>
          <Route path="/perfil/:business_username/catalogo" exact>
            <CatalogoPerfil />
          </Route>
          <Route path="/perfil/:business_username/catalogo-detalle/:code" exact>
            <CatalogoDetallePerfil />
          </Route>
          <Route path="/pago/:code" exact>
            <InfoPago />
          </Route>
          <Route path="/confirmar-pago/:code?" exact>
            <Confirmacion />
          </Route>
          <Route path="/informacion-pago" exact component={InfoPago} />
          <Route path="/tarjeta" exact>
            <Tarjeta />
          </Route>
          <Route path="/tarjeta-debito" exact>
            <TarjetaDebito />
          </Route>
          <Route path="/tigo-money" exact>
            <TigoMoney />
          </Route>
          <Route path="/confirmacion" exact>
            <Confirmacion />
          </Route>
          <Route path="/pendiente" exact>
            <Pendiente />
          </Route>
          <Route path="/error" exact>
            <Error />
          </Route>
          <Route path="/pago-rechazado" exact>
            <PagoRechazado />
          </Route>
          <Route path="/billetera-personal" exact>
            <BilleteraPersonal />
          </Route>
          <Route path="/zimple" exact>
            <Zimple />
          </Route>

          {/*===== MEJORA NEGOCIO =====*/}
          <Route path="/negocio-catalogo" exact>
            <NegocioCatalogo
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/negocio-catalogo-producto/:code" exact>
            <NegocioCatalogoProducto />
          </Route>
          <Route path="/negocio-planes" exact>
            <NegocioPlanes
              setCompanyNameHeader={handleCompanyName.bind(this)}
            />
          </Route>
          <Route path="/negocio-envio-dinero" exact>
            <NegocioEnvioDinero />
          </Route>
          {/*===== FIN MEJORA NEGOCIO =====*/}

          <Route path={"/guia"} exact component={Guia} />
          {/* <Route>
            <Redirect to="/" />
          </Route> */}
        </Switch>
      </>
    );
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          teoIdToken: teoIdToken,
          userId: userId,
          teoId: teoId,
          email: email,
          name: name,
          lastname: lastname,
          profileId: profileId,
          profile: profile,
          upgrade: upgrade,
          cellphone: cellphone,
          photo: photo,
          lastLogin: lastLogin,
          planFee: planFee,
          onlinePayMethodId: onlinePayMethodId,
          manualTransfer: manualTransfer,
          customerBusiness: customerBusiness,
          is_collab: is_collab,
          productsPlan: productsPlan,
          userAdmin: userAdmin,
          login: login,
          logout: logout,
          upgraded: upgraded,
          updateAuth: updateAuth,
        }}
      >
        <Router>
          <ThemeProvider theme={theme}>
            {" "}
            {/* Se agrega temporalmente condicion por nombre de pathanme mejoras-user-pago para diferenciar headers
            Reemplazar luego por su correspondiente */}
            {token &&
            ![
              "signin",
              "perfil",
              "negocio-perfil",
              "pago",
              "tarjetas",
              "agregar-tarjeta",
              "confirmar-pago",
              "pago-user-debito",
              "pago-user-credito",
              "aceptar_colaborador",
              "signin-alt",
            ].includes(pathname.split("/")[1]) ? (
              <div className={classes.root}>
                <Suspense fallback={<div className="center"></div>}>
                  {HiddenSidebar}
                  <div className={classes.content}>
                    <CurrentLocationDisplay
                      token={token}
                      minimal={minimal}
                      toolbarMin={classes.toolbarMin}
                      toolbar={classes.toolbar}
                      profileId={profileId}
                      numberNotifications={numberNotifications}
                      onRemove={onRemove}
                      last_rows={last_rows}
                      updateRows={updateRows}
                      onClick={handleDrawerToggle}
                      contents={classes.contents}
                      contents2={classes.contents2}
                      headerless={headerless}
                    >
                      {routes}
                    </CurrentLocationDisplay>
                    <div className="footer">
                      {lastLogin ? (
                        <p>
                          Último ingreso:
                          {new Date(lastLogin).toLocaleString("es-PY", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                          hs
                        </p>
                      ) : (
                        <p>Último ingreso: 9 jun 2021 · 08:10 hs</p>
                      )}
                      <p>Teo Pagos: 2021 - Todos los derechos reservados</p>
                    </div>
                  </div>
                </Suspense>
              </div>
            ) : token && pathname == "/aceptar_colaborador" ? (
              <div className={classes.root2}>
                <Suspense fallback={<div className="center"></div>}>
                  <div className={classes.content2}>
                    <div className={classes.toolbar2}>
                      <HeaderLogo />
                    </div>
                    <div className="mobileContainer">{routes}</div>
                  </div>
                </Suspense>
              </div>
            ) : token &&
              [
                "pago",
                "tarjetas",
                "agregar-tarjeta",
                "pago-user-credito",
                "pago-user-debito",
                "confirmar-pago",
                "aceptar_colaborador",
                "signin-alt",
              ].includes(pathname.split("/")[1]) ? (
              <div className={classes.root2}>
                <Suspense fallback={<div className="center"></div>}>
                  <div className={classes.content2}>
                    <div className={classes.toolbar2}>
                      <HeaderUser companyName={companyName} />
                    </div>
                    <div
                      {...(pathname !== "/home" ||
                        (pathname !== "/guia" && {
                          className: "mobileContainer containerTop",
                        }))}
                    >
                      {routes}
                    </div>
                  </div>
                </Suspense>
              </div>
            ) : (
              <div
                className={pathname !== "/home" ? classes.root2 : classes.root3}
              >
                <Suspense fallback={<div className="center"></div>}>
                  <div className={classes.content2}>
                    <div className={classes.toolbar2}>
                      {[
                        "home",
                        "/",
                        "guia",
                        "signin",
                        "negocio-perfil",
                      ].includes(pathname.split("/")[1]) ? (
                        ""
                      ) : [
                          "perfil",
                          "negocio-envio-dinero",
                          "negocio-catalogo",
                          "negocio-planes",
                        ].includes(pathname.split("/")[1]) ? (
                        ""
                      ) : (
                        <HeaderInvitado
                          companyName={companyName}
                          logoOnly={false}
                        />
                      )}
                    </div>
                    <div
                      {...(pathname !== "/home" ||
                        (pathname !== "/guia" && {
                          className: "mobileContainer containerTop",
                        }))}
                    >
                      {routes}
                    </div>
                  </div>
                </Suspense>
              </div>
            )}
          </ThemeProvider>
        </Router>
      </AuthContext.Provider>
    </>
  );
}

function CurrentLocationDisplay({
  children,
  token,
  minimal,
  toolbarMin,
  toolbar,
  profileId,
  numberNotifications,
  onRemove,
  last_rows,
  updateRows,
  contents,
  contents2,
  onClick,
  headerless,
}) {
  const location = useLocation();
  const handleDrawerTogglesss = () => {
    onClick();
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [headersShow, setHeadersShow] = useState(true);
  // className={
  //         (isSmallScreen &&
  //         headerless
  //           .map((item) => item.toString())
  //           .includes(location.pathname.split("/")[1])
  //           ? contents
  //           : contents2) + " mobileContainer"
  //       }
  useEffect(() => {
    if (
      token &&
      !isSmallScreen &&
      headerless
        .map((item) => item.toString())
        .includes(location.pathname.split("/")[1])
    ) {
      setHeadersShow(false);
    } else {
      setHeadersShow(true);
    }
  });

  return (
    <>
      {headersShow && (
        <div
          className={[minimal ? toolbarMin : toolbar, " mobileHeader"].join(
            " "
          )}
        >
          <Header
            disabled={profileId == 1 ? true : false}
            numberNotifications={numberNotifications}
            onRemove={onRemove}
            last_rows={last_rows}
            updateRows={updateRows}
            onClick={handleDrawerTogglesss}
          />
        </div>
      )}
      <div
        className={(headersShow ? contents : contents2) + " mobileContainer"}
      >
        {children}
      </div>
    </>
  );
}

export default App;
