import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SvgIcon from "@material-ui/core/SvgIcon";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import SearchBarDialog from "./SearchBarDialog";

//customs
import ButtonIcons from "./ButtonIcons";
import ReactTimeAgo from "react-time-ago";
import { Link, Button } from "@material-ui/core";

function ArrowIn() {
  return (
    <svg
      width="13.5px"
      height="13.5px"
      viewBox="0 0 13.5 13.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle stroke="#02224B" cx="2.9" cy="10.6" r="2.2" />
      <path stroke="#02224B" d="M6.2,7.3l6.5-6.5" />
      <path stroke="#02224B" d="M7.3,0.8h5.5v5.5" />
    </svg>
  );
}
function ArrowOut() {
  return (
    <svg
      width="13.5px"
      height="13.5px"
      viewBox="0 0 13.5 13.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse stroke="#02224B" cx="10.6" cy="10.6" rx="2.2" ry="2.2" />
      <path stroke="#02224B" d="M0.8,0.8l6.5,6.5" />
      <path stroke="#02224B" d="M1.8,7.3h5.5V1.8" />
    </svg>
  );
}
function ArrowUser() {
  return (
    <svg
      width="13.5px"
      height="14.3px"
      viewBox="0 0 13.5 14.3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#02224B"
        d="M6.8,6.8c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S5.1,6.8,6.8,6.8z"
      />
      <path stroke="#02224B" d="M12.8,13.5c0-2.8-2.7-5-6-5s-6,2.2-6,5" />
    </svg>
  );
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "#F9F9F9",
    "&:hover": {
      backgroundColor: "rgba(255,255,255, 0.25)",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    height: 40,
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    backgroundColor: "#F9F9F9",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  toolbars: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: "#02224B",
    fontWeight: 500,
    fontSize: 14,
    "&hover": {
      backgroundColor: "#02224B !important",
      color: "#37ED9A !important",
    },
  },
  root: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  notification: {
    background: "#F9F9F9",
    margin: 5,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function NotificationIcon(props) {
  const { fill, stroke } = props;
  return (
    <SvgIcon {...props}>
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill={fill ? fill : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1793 17.1547H4.82031L7.09416 5.79103C7.32105 4.65696 7.9338 3.63651 8.82817 2.90328C9.72255 2.17006 10.8433 1.76934 11.9998 1.76929V1.76929C13.1563 1.76934 14.2771 2.17006 15.1714 2.90328C16.0658 3.63651 16.6786 4.65696 16.9054 5.79103L18.0952 11.7401L19.1793 17.1547Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.76904 17.1548H21.2306"
          stroke={stroke ? stroke : "black"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0767 17.1548C15.0767 17.9709 14.7525 18.7535 14.1755 19.3306C13.5985 19.9077 12.8158 20.2319 11.9998 20.2319C11.1837 20.2319 10.4011 19.9077 9.82406 19.3306C9.24703 18.7535 8.92285 17.9709 8.92285 17.1548"
          stroke={stroke ? stroke : "black"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default function Header(props) {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [errorMsg, setErrorMsg] = useState();
  const [rows, setRows] = useState(false);
  const {
    disabled,
    onClick,
    numberNotifications,
    onRemove,
    last_rows,
    updateRows,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notiAnchorEl, notiSetAnchorEl] = React.useState(null);
  const open = Boolean(notiAnchorEl);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleDrawerToggle = () => {
    onClick();
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenu = (event) => {
    notiSetAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    notiSetAnchorEl(null);
  };

  const [openSearch, setOpen] = React.useState(false);

  const handleOpenSearch = () => {
    setOpen(true);
  };

  const handleCloseSearch = () => {
    setOpen(false);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const handleUpdateViewed = async (items) => {
    if (auth) {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Token " + auth.token,
        };
        const body = JSON.stringify({ objs: items });
        const response = await sendRequest(
          `${process.env.REACT_APP_TEOPAGOS_URI}/user/my_notifications/update_viewed/`,
          "PUT",
          body,
          headers
        );
        if (response) {
          onRemove(items[0]);
          updateRows(response.rows);
        } else {
          setErrorMsg(response.errors.message);
        }
      } catch (e) {
        console.log("error:");
        console.log(e);
        setErrorMsg("Ha ocurrido un error.");
      }
    }
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.toolbars}>
          <div className="toolbarMobile">
            <div
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="logoMobile">
            <img src={`/LogoTeoPagosWhite.svg`} alt="" />
          </div>
          <div className="actionMobile">
            {/* <SearchBarDialog /> */}

            <div
              className="notiMobile"
              aria-label="Notificaciones"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <img src={"/icons/Notification-2.svg"} />
            </div>
          </div>
          <div className={classes.grow + " growDesktop"} />
          <div className={classes.sectionDesktop}>
            {/*             <ButtonIcons
              className="buttonPrimary"
              disabled={disabled}
              label="Enviar cobro rápido"
              to="/link-crear"
            /> */}
            <IconButton
              color="inherit"
              className={classes.notification}
              aria-label="Notificaciones"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <Badge
                {...(numberNotifications > 0 && {
                  badgeContent: numberNotifications,
                })}
              >
                <NotificationIcon />
              </Badge>
            </IconButton>
            <Menu
              className="subContainer"
              id="menu-appbar"
              anchorEl={notiAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <li className="subTitle">Notificaciones</li>
              {last_rows &&
                last_rows.map((row, i) => {
                  return (
                    <li key={i} className="subRow">
                      <div className="subItem">
                        <div className="subIcon">
                          {row.category === "collection" ? (
                            <ArrowOut />
                          ) : row.category === "payment" ? (
                            <ArrowIn />
                          ) : (
                            <ArrowUser />
                          )}
                        </div>
                        <div className="subText">
                          <h3>{row.name}</h3>
                          <p>{row.name}</p>
                        </div>
                      </div>
                      <div className="subBottom">
                        <div></div>
                        <span>
                          <ReactTimeAgo
                            date={new Date(row.created_at)}
                            locale="es-PY"
                            timeStyle="round-minute"
                          />
                        </span>
                        <Link
                          onClick={() => {
                            handleUpdateViewed([row.id]);
                            handleClose();
                          }}
                        >
                          Marcar como leido
                        </Link>
                      </div>
                    </li>
                  );
                })}
              <li className="subAll">
                <Link
                  component={RouterLink}
                  to="/notificaciones"
                  onClick={handleClose}
                >
                  Ver todas las notificaciones
                </Link>
              </li>
            </Menu>
          </div>
        </Toolbar>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openSearch}
          onClose={handleCloseSearch}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openSearch}>
            <div className="modalBox modalFilter">
              <div className="modalClose" onClick={handleCloseSearch}>
                <img src={"../icons/Close.svg"} />
              </div>
              <div className="searchBox">
                <div className="searchTitle">Buscador</div>
                <div className={classes.search + " searchInput"}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Buscar por..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
                <div className="searchButton">
                  <Button color="primary" variant="contained" disableElevation>
                    Buscar
                  </Button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </AppBar>
    </div>
  );
}
