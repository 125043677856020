import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formObs: {
    display: "block",
    fontSize: 12,
    fontWeight: 400,
    color: "#d1d1d1",
    marginTop: 5,
    marginBottom: 24,
  },
  formObsLast: {
    display: "block",
    fontSize: 12,
    fontWeight: 400,
    color: "#d1d1d1",
    marginTop: 5,
  },
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "auto",
    background: "#f5f5f5",
  },
  contents: {
    background: "#f5f5f5",
    padding: "20px 45px",
    marginTop: 70,
    // marginBottom: 120,
    minHeight: "calc(100vh - 300px)",
  },
  contents2: {
    background: "#f5f5f5",
    // padding: "20px 45px",
    // marginTop: 70,
    // marginBottom: 120,
    minHeight: "calc(100vh - 300px)",
  },
  content2: {
    flexGrow: 1,
    padding: "0",
  },
  root: {
    display: "flex",
    height: "100vh",
    background: "linear-gradient(180deg, #083876 0%, #02224B 100%)",
  },
  root2: {
    display: "flex",
    background: "#F9F9F9",
    // padding: 5,
    height: "100vh",
  },
  root3: {
    display: "flex",
  },
  toolbar: {
    background: "transparent",
    width: "calc(100% - 300px)",
    zIndex: 300,
    position: "fixed",
    right: 0,
    transition: "all .5s",
  },
  toolbarMin: {
    background: "transparent",
    width: "calc(100% - 120px)",
    zIndex: 300,
    position: "fixed",
    right: 0,
    transition: "all .5s",
  },
  toolbar2: {
    background: "transparent",
    width: "100%",
    zIndex: 20,
    position: "fixed",
    right: 0,
  },

  //==========================================================
  //* ======= HEADER ===== //
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    width: "100%",
    height: "96px",
    /*position: fixed; /*Fija la posición*/
    boxShadow: "10px 0px 25px rgba(0, 0, 0, 0.05)",
    "-webkit-box-shadow": "10px 0px 25px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
      width: "100%",
      height: "70px",
    },
  },
  containerHeader: {
    width: "400px",
    display: "flex",
    padding: "15px 0",
    margin: "0 auto",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: `0 ${theme.spacing(2)}px`,
    },
  },

  //* ======= FIN HEADER ===== //

  containerMain: {
    flexGrow: 1,
    paddingTop: 96, //el header tiene un alto total de 70 px que seria lo que necesito bajar para mostrar el contenido
    overflow: "hidden",
    background: "#F9F9F9",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "71px",
    },
  },

  containerMaiNegocio: {
    flexGrow: 1,
    //paddingTop: "70px", //el header tiene un alto total de 70 px que seria lo que necesito bajar para mostrar el contenido
    overflow: "hidden",
    background: "#F9F9F9",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      //paddingTop: "71px",
    },
  },

  sectionBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingBottom: theme.spacing(14), // 100px de separación con el footer
    overflowY: "auto",
    //maxHeight: `calc(100vh - ${theme.spacing(7)}px)`, //Altura máxima del cuerpo para mantener espacio para el encabezado y el pie de página

    [theme.breakpoints.down("xs")]: {
      //Cambia el estilo en dispositivos móviles (ancho de pantalla menor o igual a 'sm')
      padding: `0 ${theme.spacing(2)}px`,
      paddingBottom: theme.spacing(15), // 100px de separación con el footer
    },
  },

  //*===== CONTENEDOR TITULO + ICONO =====*/
  containerBoxBody1: {
    display: "flex",
    gridGap: "10px",
    alignItems: "center",
    padding: "20px 0",
    boxSizing: "border-box",
    height: "68px",
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      //maxWidth: 343,
      padding: `0 ${theme.spacing(2)}px`,
      background: "#fff",
      marginBottom: `${theme.spacing(2)}px`,
      boxShadow: "0 10px 25px 0 rgba(0, 0, 0, 0.05)",
      "-webkit-box-shadow": "0 10px 25px 0 rgba(0, 0, 0, 0.05)",
    },
  },

  //* ===== CONTENEDOR SOLO TITULO SIN OTRO ELEMENTO =====*/
  containerBoxBody2: {
    display: "flex",
    gridGap: "10px",
    alignItems: "center",
    padding: "20px 0",
    boxSizing: "border-box",
    height: "68px",
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  //*===== CONTENEDOR CON BORDE REDONDEADO =====*/
  containerBody: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 10px 25px 0px #0000000d",
    "-webkit-box-shadow": "0px 10px 25px 0px #0000000d",
    boxSizing: "border-box",
    height: 72,
    width: "400px",
    marginBottom: `${theme.spacing(1)}px`,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 68,
    },
  },

  containerBody1: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 10px 25px 0px #0000000d",
    "-webkit-box-shadow": "0px 10px 25px 0px #0000000d",
    boxSizing: "border-box",
    minHeight: "68px", // Altura mínima del contenedor
    width: "800px",
    marginBottom: `${theme.spacing(1)}px`,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  containerBodyDetail: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  tabsDetail: {
    display: "grid",
    gridTemplateColumns: "28px 1fr",
    gridGap: "10px",
    alignItems: "center",
  },

  tabsIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    height: "28px",
    "& img": {
      maxWidth: "100%",
      display: "block",
    },
    [theme.breakpoints.down("xs")]: {
      width: 24,
      height: 24,
    },
  },
  tabsIconBig: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "56px",
    height: "56px",
    "& img": {
      maxWidth: "100%",
      display: "block",
      [theme.breakpoints.down("xs")]: {
        width: 32,
        height: 32,
      },
    },
  },
  tabsDetailText: {
    "& p": {
      margin: "0",
      fontSize: "14px",
    },
  },

  //* =======LINKS new====== //
  links: {
    cursor: "pointer",
    color: "#000",
    transition: "all 0.5s",
    "&:hover": {
      boxShadow: "5px 5px 5px 5px #ddd",
      "-webkit-box-shadow": "5px 5px 5px 5px #ddd",
    },
  },
  buttonPrimary: {
    width: "200px !important",
    height: "48px !important",
    boxShadow: "none !important",
    color: "#FFFFFF !important",
    backgroundColor: "#02224B !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#37ED9A",
      color: "#000000",
      transition: "background-color 200ms ease-in-out, color 200ms ease-in-out",
    },
  },

  buttonPrimaryInactive: {
    width: "300px !important",
    height: "48px !important",
    boxShadow: "none !important",
    color: "#FFFFFF !important",
    backgroundColor: "#8C8C8C !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#8C8C8C",
      color: "#ffffff",
      transition: "background-color 200ms ease-in-out, color 200ms ease-in-out",
    },
  },

  buttonPrimaryDisable: {
    width: "300px !important",
    height: "48px !important",
    boxShadow: "none !important",
    color: "#FFFFFF !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#37ED9A",
      color: "#000000",
      transition: "background-color 200ms ease-in-out, color 200ms ease-in-out",
    },
  },

  buttonSecondary: {
    width: "300px",
    height: "48px",
    boxShadow: "none",
    backgroundColor: "#fff",
    border: "1px solid #02224B",
    color: "#02224B",
    fontSize: "14px",
    fontWeight: "500",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#37ED9A",
      color: "#000000",
      border: "none",
      transition: "background-color 200ms ease-in-out, color 200ms ease-in-out",
    },
  },

  buttonPrimaryLarge: {
    width: "400px",
    height: "48px",
    boxShadow: "none",
    color: "#FFFFFF",
    backgroundColor: "#02224B",
    fontSize: "14px",
    fontWeight: "500",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#37ED9A",
      color: "#000000",
      transition: "background-color 200ms ease-in-out, color 200ms ease-in-out",
    },
  },
  linksOnly: {
    cursor: "pointer",
  },

  //* =======INPUTS new===== //
  inputs: {
    padding: "18px 0px 18px 0px",
  },
  input: {
    border: "1px solid #D1D1D1",
    "&:hover:not(.disabled):not(.focused):not(.error)": {
      border: "1px solid #000",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        border: "1px solid #000",
        color: "#000",
      },
    },
  },
  label: {
    color: "#02224B",
    textAlign: "left",
    marginBottom: 5,
  },

  //* =======ACORDEON ===== //
  containerAccordion: {
    position: "fixed",
    display: "block",
    width: "400px",
    margin: "0 auto",
    bottom: 70,
    right: "0",
    left: "0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      bottom: 49,
    },
  },

  boxAccordion: {
    borderTopLeftRadius: "8px !important",
    borderTopRightRadius: "8px !important",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1) !important",
  },

  titleAccordion: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      margin: "0px",
    },
  },

  lineAccordeon: {
    borderBottom: "1px solid #e0e0e0",
    margin: "0 0 20px 0",
  },

  boxDetailsAccordion: {
    padding: "0 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: "20px",
    "& p": {
      margin: "0px",
    },
  },
  //* ======= FIN ACORDEON ===== //

  //* ======= FOOTER ===== //
  footerCheckout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "auto",
    position: "fixed",
    bottom: "0",
    background: "#fff",
    boxShadow: "10px 0px 25px rgba(0, 0, 0, 0.05) !important",
    "-webkit-box-shadow": "10px 0px 25px rgba(0, 0, 0, 0.05) !important",
    [theme.breakpoints.down("sm")]: {},
  },
  containerFooter: {
    width: "400px",
    height: "40px",
    padding: "15px 20px",
    gridGap: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 0,
      height: 48,
    },
  },
  //* ======= FIN FOOTER ===== //

  //* ======= FORMULARIO INVITADO ===== //
  formControl: {
    display: "block",
    position: "relative",
    marginBottom: 15,
  },
  inputFormInvitado: {
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "3px",
    border: "1px solid #d1d1d1",
    //marginBottom: "34px",
    height: "50px",
    width: "100%",
    boxSizing: "border-box",
  },

  boxInvitado: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    marginBottom: 8,
  },

  boxInvitado1: {
    display: "flex",
    justifyContent: "space-between",
  },

  boxInvitado2: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 8,
  },

  buttonInvitado: {
    width: "344px !important",
    height: "48px !important",
    borderRadius: "3px !important",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "8px !important",
    },
  },
  //* ======= FIN FORMULARIO INVITADO ===== //

  containerButton: {
    display: "flex",
    height: "70px",
    backgroundColor: "white",
    position: "fixed",
    width: "100%",
    margin: "0 auto",
    bottom: "70px",
    boxSshadow: "0 10px 25px 0 rgba(0, 0, 0, 0.05) !important",
    "-webkit-box-shadow": "0 10px 25px 0 rgba(0, 0, 0, 0.05) !important",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      //backgroundColor: "unset",
    },
  },
  containerButtonFooter: {
    display: "flex",
    height: 96,
    backgroundColor: "white",
    position: "fixed",
    width: "100%",
    margin: "0 auto",
    bottom: "70px",
    boxSshadow: "0 10px 25px 0 rgba(0, 0, 0, 0.05) !important",
    "-webkit-box-shadow": "0 10px 25px 0 rgba(0, 0, 0, 0.05) !important",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      //backgroundColor: "unset",
      height: 86,
      bottom: 49,
    },
  },

  //*===== PAGINA NEGOCIO CATALOGO =====//
  containerBodyNegocio: {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "680px",
    borderRadius: "10px",
    boxShadow: "0px 10px 25px 0px #0000000d",
    "-webkit-box-shadow": "0px 10px 25px 0px #0000000d",
    boxSizing: "border-box",
    marginBottom: `${theme.spacing(1)}px`,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  containerInput: {
    boxSizing: "border-box",
    padding: "0 20px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #f3f3f3",
  },
  //*===== BLOQUE PRODUCTO =====//
  containerProduct: {
    height: 85,
    display: "flex",
    padding: "24px 16px",
    borderBottom: "1px solid #f3f3f3",
    alignItems: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      height: 82,
    },
  },
  containerProductCatalogo: {
    height: 90,
    display: "flex",
    padding: "24px 20px",
    borderBottom: "1px solid #f3f3f3",
    alignItems: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {},
  },

  productPreview: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flex: "0 0 40px" /* Tamaño fijo de 40px */,
    borderRadius: "5px",
    border: "1px #D1D1D1 solid",
    overflow: "hidden",
    borderBottom: "1px solid #f3f3f3",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },

  productInfo: {
    flex: "1" /* Se adapta al espacio disponible en el medio del contenedor de producto*/,
    display: "flex",
    flexDirection: "column",
    margin: "0 16px",
  },

  productCount: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: "0 0 60px" /* Tamaño fijo de 60px */,
    "& img": {
      width: "16px",
      height: "16px",
    },
  },

  //*===== FIN PAGINA NEGOCIO CATALOGO =====//
  //*===== PAGINA PAGINA CATALOGO =====//
  //*===== BOTON FILTRAR =====//
  btnFilter: {
    display: "inline-flex",
    alignItems: "center",
  },
  //*===== BOTON FILTRAR =====//

  //*===== BLOQUE AGREGAR PRODUCTO =====//
  containerAdd: {
    display: "flex",
    alignItems: "center",
    padding: "24px 16px",
    borderBottom: "1px solid #f3f3f3",
    textDecoration: "none !important",
    "&:hover": {
      "& $btnAdd i": {
        transition: "all 0.3s ease-in-out",
        color: "#00bb66",
      },
    },
  },
  btnAdd: {
    display: "flex",
    alignItems: "center",
    "& i": {
      display: "inline-flex",
      marginRight: "18px",
    },
  },
  //*===== FIN BLOQUE AGREGAR PRODUCTO =====//

  catalogoProduct: {
    display: "inline-flex",
    overflow: "hidden",
    flex: "1",
    "& img": {
      borderRadius: "5px",
      border: "1px #D1D1D1 solid",
      borderBottom: "1px solid #f3f3f3",
      width: "32px",
      height: "32px",
      objectFit: "cover",
    },
  },
  catalogoinfo: {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 150px",
  },
  catalogoMenuProduct: {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 20px",
    "& i": {
      "-webkit-transition": "all 0.3s ease-in-out",
      transition: "all 0.3s ease-in-out",
    },
  },
  //*===== FIN PAGINA CATALOGO =====//

  //*===== PAGINA DETALLE PRODUCTO NEGOCIO =====//
  containerBodyProduct: {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "400px",
    borderRadius: "10px",
    boxShadow: "0px 10px 25px 0px #0000000d",
    "-webkit-box-shadow": "0px 10px 25px 0px #0000000d",
    boxSizing: "border-box",
    marginBottom: `${theme.spacing(1)}px`,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  boxProductImage: {
    width: 400,
    height: 300,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& img": {
      width: "auto",
      height: "100%",
      objectFit: "contain",
    },
  },
  boxProductDetail: {
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    boxShadow: "0px -10px 25px rgba(0, 0, 0, 0.05)",
    borderRadius: 10,
    boxSizing: "border-box",
  },

  //*===== FIN PAGINA DETALLE PRODUCTO NEGOCIO =====//

  //*===== PAGINA PLANES =====//
  boxPlaneRadio: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "0 0 40px" /* Tamaño fijo de 40px */,
  },
  boxPlaneName: {
    //flex: "1" /* Se adapta al espacio disponible en el medio del contenedor de producto*/,
    display: "flex",
    flexDirection: "column",
    margin: "0 0 0 15px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 0 4px",
    },
  },
  boxPlaneDetail: {
    display: "flex",
    flexDirection: "column",
    //flex: "0 0 200px",
    alignItems: "flex-end",
  },
  //*===== PAGINA PLANES =====//

  //*===== PAGINA CATALOGO NUEVO PRODUCTO =====//
  buttonUpload: {
    height: 50,
    display: "inline-block",
    padding: "14px 10px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #d1d1d1",
    borderRadius: "3px",
    width: "100%",
    boxShadow: "none",
    WebkitBoxShadow: "none",
    transition: "background-color 0.5s",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  //*===== FIN PAGINA CATALOGO NUEVO PRODUCTO =====//

  //*===== MENU MOBILE =====//
  FooterFixed: {
    display: "grid",
    position: "fixed",
    backgroundColor: "#fff",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "auto",
    padding: "20px 0",
    zIndex: 100,
    gridTemplateColumns: "repeat(5, 1fr)",
    alignItems: "center",
    boxShadow: "0px -10px 25px rgba(0, 0, 0, 0.05)",
    WebkitBoxShadow: "0px -10px 25px rgba(0, 0, 0, 0.05)",
  },
  footerBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  footerBoxActive: {
    "& $bodyXSR": {
      fontWeight: "bold !important",
      fontSize: "13px !important",
    },
    "& $footerIcon svg path": {
      stroke: "#02224b",
    },
  },

  footerIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    marginBottom: "6px",
  },

  footerCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    borderRadius: "100%",
    backgroundColor: "#37ed9a",
    WebkitTransition: "all 0.3s ease-in-out",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "#02224b",
    },
  },

  //*===== FIN MENU MOBILE =====//
  //*===== ESTILOS TEXTOS - FONTS =====//

  //*===== BODY LARGE ===== (REGULAR, MEDIUM, BOLD) =====//
  bodyLR: {
    fontWeight: "normal !important",
    fontSize: "18px !important",
    lineHeight: "21px !important",
  },
  bodyLM: {
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "21px !important",
  },
  bodyLB: {
    fontWeight: "bold !important",
    fontSize: "18px !important",
    lineHeight: "21px !important",
  },
  //*===== FIN BODY LARGE ===== (REGULAR, MEDIUM, BOLD) =====//

  //*===== BODY MEDIUM ===== (REGULAR, MEDIUM, BOLD) =====//
  bodyMR: {
    fontWeight: "normal !important",
    fontSize: "16px !important",
    lineHeight: "19px !important",
  },
  bodyMM: {
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "19px !important",
  },
  bodyMB: {
    fontWeight: "bold !important",
    fontSize: "16px !important",
    lineHeight: "19px !important",
  },
  //*===== FIN BODY MEDIUM ===== (REGULAR, MEDIUM, BOLD) =====//

  //*===== BODY SMALL ===== (REGULAR, MEDIUM, BOLD) =====//
  bodySR: {
    fontWeight: "normal !important",
    fontSize: "14px !important",
    lineHeight: "17px !important",
  },
  bodySM: {
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "17px !important",
  },
  bodySB: {
    fontWeight: "bold !important",
    fontSize: "14px !important",
    lineHeight: "17px !important",
  },
  //*===== FIN BODY SMALL ===== (REGULAR, MEDIUM, BOLD) =====//

  //*===== BODY EXTRA SMALL ===== (REGULAR, MEDIUM, BOLD) =====//

  bodyXSR: {
    fontWeight: "normal !important",
    fontSize: "12px !important",
    lineHeight: "14px !important",
  },
  bodyXSM: {
    fontWeight: "500 !important",
    fontSize: "12px !important",
    lineHeight: "14px !important",
  },
  bodyXSB: {
    fontWeight: "bold !important",
    fontSize: "12px !important",
    lineHeight: "14px !important",
  },
  //*===== FIN BODY EXTRA SMALL ===== (REGULAR, MEDIUM, BOLD) =====//

  //*===== FIN ESTILOS TEXTOS =====//

  //*===== PAGINA CREAR PLAN SUSCRIPCIONES =====//
  containerPlan: {
    width: 680,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  accordionBox: {
    padding: "20px 24px",
    background: "#ffffff",
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.05)",
    borderRadius: 8,
    overflow: "hidden",
    border: "none",
    width: "100%",
    boxSizing: "border-box",
  },

  accordionSummary: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: "initial !important",
    padding: 0,
    "& i": {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width: 12,
      height: 12,
      marginLeft: 5,
      position: "relative",
      transform: "rotate(0deg)",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0px !important",
      justifyContent: "space-between",
    },
    "& .Mui-expanded i": {
      transform: "rotate(180deg)",
    },
    "& i img": {
      display: "block",
      width: "100%",
    },
  },

  //==========================================================
}));

export default useStyles;
